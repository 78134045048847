<p-sidebar class="uploadCandidate-slider" [(visible)]="showUploadSlider" styleClass="p-sidebar-lg"
    [showCloseIcon]="true" position="right" (onHide)="getCandidates()" [style]="{width: '50rem'}">
    <div class="sidebar-header">
        <div class="uploadCandidate-header">
            <div class="uploadCandidate-heading">Upload Candidates</div>
            <div class="header-btn">
                <button pButton pRipple type="submit" label="Upload" (click)="uploadCandidates(stage.stageId)"
                    class="p-button-raised p-button-text button-align" [loading]="uploadBtnLoading"></button>
            </div>
        </div>
        <p-divider class="p-divider"></p-divider>
    </div>

    <div class="upload">
        <p class="upload-heading">Upload Template to Add Bulk Candidates</p>
        <div class="bulkUpload-dropzone" ngx-dropzone
            [accept]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
            [multiple]="false" (change)="onSelectExcel($event)">
            <ngx-dropzone-label *ngIf="!uploadingFile">
                <div class="dropzone-label">
                    <em class="pi pi-cloud-upload"></em>
                    <p class="upload-text">Click to <span [style]="{color: 'var(--fc-primary)'}">upload</span>
                        or
                        Drag & Drop</p>
                    <p class="format-text">.XLS, .XLSX Formate only</p>
                </div>
            </ngx-dropzone-label>
            <div *ngIf="uploadingFile">
                <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
                </p-progressSpinner>
            </div>
            <div *ngIf="fileBase64.length">
                <ngx-dropzone-preview class="file-preview" ngProjectAs="ngx-dropzone-preview" *ngFor="let f of excel"
                    [file]="f" [removable]="true" (removed)="onRemoveExcel(f)">

                    <ngx-dropzone-label class="dropzone-preview-label">{{f.name}}</ngx-dropzone-label>
                </ngx-dropzone-preview>
            </div>
        </div>
    </div>
</p-sidebar>

<p-sidebar class="scheduleCandidate-slider" [(visible)]="showScheduleSlider" styleClass="p-sidebar-lg"
    [showCloseIcon]="true" position="right" [style]="{width: '50rem'}" (onHide)="bulkSchedule()">
    
    <div class="scheduleCandidate-header">
        <div class="scheduleCandidate-heading">Schedule Bulk Interview</div>
        <div class="header-btn">
            <button pButton pRipple type="submit" label="Submit" (click)="scheduleFileUpload()"
                class="p-button-raised p-button-text button-align" [loading]="scheduleBtnLoading"></button>
        </div>
    </div>
    <p-divider class="p-divider"></p-divider>

    <div class="scheduleCandidate">
        <div class="scheduleCandidate-container">
            <div class="interview-heading">Select Candidates</div>
            <div class="radio-group">
                <div class="radioButton-container">
                    <div>
                        <p-radioButton [(ngModel)]="scheduleRadio" value="all" name="allButton" inputId="all">
                        </p-radioButton>
                        <label>All</label>
                    </div>
                </div>
                <div class="radioButton-container">
                    <div>
                        <p-radioButton [(ngModel)]="scheduleRadio" value="unScheduled" name="unscheduledButton" inputId="unScheduled">
                        </p-radioButton>
                        <label>Unscheduled</label>
                    </div>
                </div>
            </div>
                <div class="download-template">
                    <div class="download-heading">Download Template</div>
                    <div>
                        <div class="template-download" (click)="templateDownload()">
                            <div class="file-container" [style]="{display : 'flex'}">
                                <p class="pi pi-file-excel template-file-icon" [style]="{fontSize : '32px',display: 'flex',alignItems: 'center', color: 'lightgreen'}"></p>
                            <div class="template-file_info">
                                <p class="file-name file-text">Sample_Bulk_Invite Template</p>
                            </div>
                            </div>
                            <p class="template-download-icon">
                            <em class="pi pi-download" [style]="{fontSize: '20px', fontWeight: 'bold'}"></em></p>
                        </div>
                    </div>
                </div>
            </div>
        <p-divider class="p-divider"></p-divider>
        <div class="scheduleCandidate-container">
            <div class="upload-template">
                <div class="upload-heading">Upload Template</div>
                <div *ngIf="!templateUploaded">
                    <div class="template-upload-initial" ngx-dropzone
                    [accept]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
                    [multiple]="false" (change)="uploadTemplate($event)">
                    <div style="width: 100%;">
                        <ngx-dropzone-label>
                            <div style="display: flex;justify-content: space-between; width: 100%;">
                                <div>
                                    <div>
                                      <p>Click to <span [style]="{color: 'var(--fc-primary)'}">upload</span> or <span [style]="{color: 'var(--fc-primary)'}">Drag & Drop</span></p>
                                    </div>
                                  </div>
                                  <div>
                                      <em class="pi pi-upload" [style]="{fontSize: '24px', fontWeight: 'bold'}"></em>
                                  </div>
                            </div>
                        </ngx-dropzone-label>
                    </div>
                    </div>
                </div>
                
                <div *ngFor="let f of template">
                    <div class="template-upload" *ngIf="f.name">
                        <div class="file-container" [style]="{display : 'flex'}">
                            <p class="pi pi-file-excel template-file-icon" [style]="{fontSize : '32px',display: 'flex',alignItems: 'center', color: 'lightgreen'}"></p>
                          <div class="template-file_info">
                            <p class="file-name file-text">{{f.name}}</p>
                            <p class="file-name">{{templateSize}}</p>
                          </div>
                        </div>
                      <div class="template-upload-icon" ngx-dropzone [accept]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
                      [multiple]="false" (change)="uploadTemplate($event)">
                        <em class="pi pi-upload" [style]="{fontSize: '24px', fontWeight: 'bold'}"
                       ></em></div>
                    </div>
                </div>
            </div>
            <div class="field-format"[style]="{marginTop: '5px'}">
                <p [style]="{fontSize: '14px', fontWeight: 'light',color: '#c3c3c3'}">Please make sure the format satisfies the following :-</p>
                <p [style]="{fontSize: '12px', fontWeight: 'lighter',color: '#c3c3c3'}">Interview Date : YYYY-MM-DD</p>
                <p [style]="{fontSize: '12px', fontWeight: 'lighter',color: '#c3c3c3'}">Both Start Time and End Time : HH:MM</p>
            </div>
            <span class="sendLinkForm-elements"  *ngIf="stage.type == 'OfflineInterview'">
                <p-checkbox  name="emailNotification" [(ngModel)]="pushEmailNotification" [trueValue]="true" 
                     [value]="true" inputId="emailNotification" [binary]="true">
                </p-checkbox>
                <label for="emailNotification" [style]="{ fontSize: '14px', fontWeight: 500, paddingLeft: '5px'}">Send Email Notification</label>
            </span>
        </div> 
        <p-divider class="p-divider"></p-divider>
        <div class="report_containter" [style]="{margin: '1rem 1vw 1rem 5rem'}" *ngIf="erroredFile.length">
            <div class="report_body">
                <div class="error_report_container">
                    <div class="report-template">
                        <div class="report-heading">Error Report</div>
                        <div class="report-flex">
                            <div *ngFor="let f of erroredFile index as i">
                                <div class="template-report" (click)="errorFileDownload(f.erroredFile)">
                                    <div class="flex-dev" [style]="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">
                                        <div class="file-container" [style]="{display : 'flex'}">
                                            <p class="pi pi-file-excel template-file-icon" [style]="{fontSize : '32px',display: 'flex',alignItems: 'center', color: 'lightgreen'}"></p>
                                        <div class="template-file_info">
                                            <p class="file-name">Errored File</p>
                                            <!-- <p class="file-name">Errored File {{i}}</p> -->
                                        </div>
                                        </div>
                                        <p class="error-download-icon">
                                        <em class=" report-download pi pi-download" [style]="{fontSize: '24px', fontWeight: 'bold',paddingLeft: '10px', color: '#d5d5d5'}"></em></p>
                                    </div>
                                    <div class="report_timestamp">
                                        <p>{{f.time | date: 'h:mm a' }}</p>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
        
</p-sidebar>

<p-sidebar class="sendLink-slider" [(visible)]="sendLinkSlider" styleClass="p-sidebar-lg" [showCloseIcon]="true"
    position="right" [style]="{width: '50rem'}" (onHide)="sidenavClosed()">
    <div class="sidebar-header">
        <div class="sendLink-header">
            <div class="sendLink-heading">Send Interview Link</div>
            <div class="header-btn">
                                <button pButton pRipple type="submit" [label]="stage.type == 'OfflineInterview' ? 'Schedule Interview' : 'Send Link'" class="p-button-raised p-button-text button-align"
                    [loading]="uploadBtnLoading" (click)="sendInterviewLink(startTime, endTime)"></button>
            </div>
        </div>
    </div>
    <p-divider class="p-divider"></p-divider>
    
    <div class="sendLink">

        <div class="snedLinkForm" [formGroup]="sendLinkForm" (ngSubmit)="sendInterviewLink(startTime, endTime)">

            <span class="p-float-label sendLinkForm-elements">
                <p-calendar inputId="dateformat" formControlName="interviewDate" [showIcon]="true" [minDate]="currentDate"
                    [ngClass]="{'invalid': clicked && sendLinkForm.controls['interviewDate'].errors}" (onSelect)="dateChange()">
                </p-calendar>
                <label for="dateformat">Date<span class="imp">*</span></label>
                <app-handle-error [clicked]="clicked" [control]="sendLinkForm.get('interviewDate')"
                    name="Date"></app-handle-error>
            </span>

            <span class="p-float-label sendLinkForm-elements">
                <p-dropdown [style]="{width: '25vw'}" id="startTime" #startTime [options]="timeSlots"
                    formControlName="startTime" [editable]="true" optionLabel="timeSlot" optionValue="utcTime"
                    [autoDisplayFirst]="false"
                    [ngClass]="{'invalid': clicked && sendLinkForm.controls['startTime'].errors}">
                </p-dropdown>
                <label for="dateformat">Start Time<span class="imp">*</span></label>
                <app-handle-error [clicked]="clicked" [control]="sendLinkForm.get('startTime')"
                    name="Start Time"></app-handle-error>
            </span>

            <span class="p-float-label sendLinkForm-elements">
                <p-dropdown [style]="{width: '25vw'}" id="endTime" #endTime [options]="timeSlots"
                    formControlName="endTime" [editable]="true" optionLabel="timeSlot" optionValue="utcTime"
                    [autoDisplayFirst]="false"
                    [ngClass]="{'invalid': clicked && sendLinkForm.controls['endTime'].errors}"></p-dropdown>
                <label for="dateformat">End Time<span class="imp">*</span></label>
                <span class="errorMessage" *ngIf="clicked && !sendLinkForm.controls['endTime'].value">End Time is Required.</span>
                <div class="passwrod-error" *ngIf="clicked && sendLinkForm.controls['endTime'].value && sendLinkForm.controls['endTime'].hasError('incorrect')">
                    <p> End Time must be greater than Start Time</p>
                </div>
            </span>

            <span class="p-float-label sendLinkForm-elements" *ngIf="stage.type == 'OfflineInterview'">
                <input type="text" pInputText [style]="{width: '25vw'}" inputId="venue" #venue formControlName="venue" [ngClass]="{'invalid': clicked && sendLinkForm.controls['endTime'].errors}">
                <label for="venue">Venue <span class="imp">*</span></label>
                <app-handle-error [clicked]="clicked" [control]="sendLinkForm.get('venue')"
                    name="Venue"></app-handle-error>
            </span>
            
        </div>
        <span class="sendLinkForm-elements"  *ngIf="stage.type == 'OfflineInterview'">
            <p-checkbox name="emailNotification" [(ngModel)]="pushEmailNotification" [trueValue]="true" 
                [value]="true" inputId="emailNotification" [binary]="true">
            </p-checkbox>
            <label for="emailNotification" [style]="{ fontSize: '14px', fontWeight: 500, paddingLeft: '5px'}">Send Email Notification</label>
        </span>
    </div>

</p-sidebar>

<p-sidebar class="sendLink-slider" [(visible)]="teamsScheduleSlider" styleClass="p-sidebar-lg" [showCloseIcon]="true"
    position="right" [style]="{width: '50rem'}" (onHide)="sidenavClosed()">
    <app-calendar-invite *ngIf="teamsScheduleSlider" (emitResult)="teamsScheduleSlider = false; selectedUsersList = []; getCandidates();" [candidatesList]="selectedUsersList" [stage]="stage"></app-calendar-invite>
</p-sidebar>

<p-sidebar class="activity-slider" (onHide)="onHideActivity()" [(visible)]="stageActivitySlider" styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right" [style]="{width: '72rem'}">
    <div class="sidebar-header">
        <div class="sendLink-header">
            <div class="sendLink-heading">{{activitySliderOptions.header}}</div>
            <div class="header-btn">
                <button pButton pRipple type="submit" label="Submit" class="p-button-raised p-button-text button-align"
                    [loading]="uploadBtnLoading" (click)="activitySliderSubmit(activitySliderOptions)"></button>
            </div>
        </div>
        <p-divider class="p-divider"></p-divider>
    </div>
    <div class="activity-remarks">
        <span class="remarks-item">
            <label class="sidebar-label" for="selectedCount">Selected</label>
            <div class="selectedCount value" id="selectedCount">{{this.selectedUsersList.length}} Candidate{{this.selectedUsersList.length > 1 ? 's' : ''}}</div>
        </span>
        <span class="remarks-item">
            <label class="sidebar-label" for="stageMovement">{{activitySliderOptions.action == 'moveStage' ? 'Stage' : 'Status'}}</label>
            <div class="stageMovement value" id="stageMovement">
                <span *ngIf="activitySliderOptions?.action !== 'statusUpdate' || selectedUsersList.length == 1" class="fromStage">{{activitySliderOptions.from}}</span>
                <img *ngIf="activitySliderOptions?.action !== 'statusUpdate' || selectedUsersList.length == 1" src="../../../assets/icons-svg/arrow-right-alt.svg" alt="Arrow-right">
                <span class="toStage">{{activitySliderOptions.to}}</span>
            </div>
        </span>
        
        <span class="remarks-item">
            <label class="sidebar-label" for="">Remarks</label>
            <textarea [(ngModel)]="activityRemarks" placeholder="Type remark here" id="remarks" class="remarks" rows="8" cols="60" pInputTextarea></textarea>
        </span>

        </div>
</p-sidebar>

<div class="table" [style]="{display: 'flex', background: '#F5F5F5'}">

    <!-- Column Settings Sidebar -->
    <p-sidebar class="settingsSidebar candidateSidebar" [transitionOptions]="'0ms'" [(visible)]="showSettingsSidebar"
        [style]="{ padding: '0'}" [modal]="false">
        <p class="sidebar-title">Column Settings</p>
        <div class="{{(savedColumnSettings && savedColumnSettings.length) ? 'savedColumns-continer' : 'hidden'}}">
            <p>Saved Column</p>
            <div class="savedColumns" *ngFor="let column of savedColumnSettings"
                (click)="applySavedSettings(column.columnValue)">
                <p>{{column.columnName}}</p>
                <em class="pi pi-trash" (click)="deleteSavedColumns(column.columnId)"></em>
            </div>
        </div>
        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input type="text" pInputText placeholder="Search" [(ngModel)]="columnSearchTerm"
                (ngModelChange)="searchColumns($event)" />
        </span>
        <div *ngFor="let col of _candidatesCol" class="field-checkbox">
            <p-checkbox name="category" [value]="col" [(ngModel)]="checkedColumns" [inputId]="col.field">
            </p-checkbox>
            <label [for]="col.field" [style]="{paddingLeft: '10px'}">{{col.header}}</label>
        </div>

        <div class="sidebar-bottom">
            <div class="sidebar-bottom-apply" [ngClass]="{'hidden' : displaySaveColumnSettings}">
                <div class="sidebar-bottom-buttons">
                    <button pButton pRipple type="button" label="Clear" class="p-button-outlined"
                        (click)="clearSelectedColumns()"></button>
                    <button pButton pRipple type="button" label="Apply" (click)="applySelectedColumns()"></button>
                </div>
            </div>
            <div class="sidebar-saveSettings" [ngClass]="{'hidden' : !displaySaveColumnSettings}">
                <em class="pi pi-times" (click)="displaySaveColumnSettings = false"></em>
                <form class="p-inputgroup" [formGroup]="columnSettings">
                    <input type="text" pInputText placeholder="Enter Filter Name" formControlName="columnName">
                    <button type="submit" pButton pRipple label="Save"></button>
                </form>
            </div>
        </div>
    </p-sidebar>

    <!-- Filter Sidebar -->
    <p-sidebar class="filterSidebar candidateSidebar" [transitionOptions]="'0ms'" [style]="{ padding: '0'}"
        [modal]="false" [baseZIndex]="0" ariaCloseLabel="Filter" [(visible)]="showFilterSidebar">
        <p class="sidebar-title">Filter</p>
        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input type="text" pInputText placeholder="Search">
        </span>

        <div class="filterSidebarContent">
            <p-accordion [multiple]="true" *ngFor="let col of _selectedColumns" [collapseIcon]="'pi pi-minus'"
                [activeIndex]="[0, 1]">
                <p-accordionTab [ngSwitch]="col.field" header={{col.header}} *ngIf="col.options">
                    <p-dropdown class="statusFilter" [style]="{width: '100%'}" [options]="col.options" id="filterOption"
                        [(ngModel)]="col.selectedFilterElement" (ngModelChange)="filterUpdate($event, col.field, col)"
                        optionLabel="label">
                    </p-dropdown>
                </p-accordionTab>
            </p-accordion>
        </div>

        <div *ngIf="filterCriteria.length" class="clear-filter_container">
            <div class="clear-filter">
                <p (click)="clearFilter()">Clear filter</p>
            </div>
        </div>

    </p-sidebar>

    <!-- Download Sidebar -->
    <p-sidebar [transitionOptions]="'0ms'" class="downloadSidebar candidateSidebar candidateListDownload"
        [modal]="false" [style]="{ padding: '0', border: '2px solid white'  }" [(visible)]="showDownloadSidebar">
        <p class="sidebar-title">Download Data</p>

        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input pInputText (ngModelChange)="searchColumns($event)" [(ngModel)]="downloadSearchTerm" placeholder="Search"
                type="text" />
        </span>

        <div *ngFor="let col of colsToDownload" class="field-checkbox">
            <p-checkbox name="category" [value]="col" [(ngModel)]="checkedColumns" [inputId]="col.field">
            </p-checkbox>
            <label [for]="col.field" [style]="{paddingLeft: '10px'}">{{col.header}}</label>
        </div>

        <div class="download-container">
            <p>Download as</p>
            <div class="radioButton-group">
                <div class="radioButton">
                    <p-radioButton [(ngModel)]="downloadAs" value="excel" name="exportButtons" inputId="excel">
                    </p-radioButton>
                    <label [style]="{color: downloadAs === 'excel' ? 'var(--fc-primary)' : 'var(--fc-light-dark)'}"
                        for="excel"><em class="pi pi-file"></em>Excel</label>
                </div>
                <div class="radioButton">
                    <p-radioButton [(ngModel)]="downloadAs" name="exportButtons" inputId="csv" value="csv">
                    </p-radioButton>
                    <label [style]="{color: downloadAs === 'csv' ? 'var(--fc-primary)' : 'var(--fc-light-dark)'}"
                        for="csv"><em class="pi pi-file-excel"></em>CSV</label>
                </div>
            </div>

            <button class="p-button-raised" pRipple pButton type="button"
                (click)="(downloadAs === 'excel') ? exportCandidate('excel') : exportCandidate('csv')"
                label="Download"></button>
            <p [style]="{color: 'var(--clr-primary)', fontSize: 'var(--fs-medium)', 'cursor': loadingDownload ? 'wait' : 'pointer' }"
                (click)="exportAllCandidates('excel')">Download All Candidates</p>
            <!-- <p [style]="{color: 'var(--clr-primary)', fontSize: 'var(--fs-medium)', 'cursor': loadingDownload ? 'wait' : 'pointer' }"
                (click)="exportRequiredFields('excel')">Download Required Fields</p> -->
        </div>
    </p-sidebar>

    <!-- Table -->
    <p-table #dt [columns]="_selectedColumns" [value]="candidateList" [loading]="loadingSpinner" [scrollable]="true"
        [(selection)]="selectedUsersList" scrollHeight="60vh" scrollDirection="both" responsiveLayout="scroll"
        [resizableColumns]="true" [rows]="pageSize" dataKey="id" [exportFilename]="exportName"
        (selectionChange)="handleSelection($event)" [globalFilterFields]="['userName', 'status', 'stage', 'email']"
        [style]="{width: showFilterSidebar || showSettingsSidebar || showDownloadSidebar ? '50vw': '70vw'}"
        (onEditInit)="inEditMode = true" (onEditComplete)="inEditMode = false">
        
        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="table-header-left">
                    <button pButton class="p-button-text simple-btn{{showSettingsSidebar ? '-active' : '' }}"
                        title="Settings"
                        (click)="showSettingsSidebar = true; showFilterSidebar = false; showDownloadSidebar = false"
                        icon="pi pi-sliders-h">
                    </button>
                    <button pButton class="p-button-text simple-btn{{showFilterSidebar ? '-active' : '' }}"
                        title="Filter"
                        (click)="showFilterSidebar = true; showSettingsSidebar = false; showDownloadSidebar = false"
                        icon="pi pi-filter">
                    </button>
                    <button pButton class="p-button-text simple-btn{{showDownloadSidebar ? '-active' : '' }}"
                        title="Download"
                        (click)="showDownloadSidebar = true; showSettingsSidebar = false; showFilterSidebar = false"
                        icon="pi pi-download">
                    </button>
                    <button pButton pRipple type="button" icon="pi pi-upload" (click)="showUploadSlider = true"
                        title="Upload" class="p-button-text simple-btn{{showUploadSlider ? '-active' : '' }}"></button>

                    <button pButton pRipple type="button" icon="pi pi-calendar" *ngIf="stageType == 'Interview' || stageType == 'OfflineInterview'" (click)="showScheduleSlider = true"
                        title="Schedule Bulk Interview" class="p-button-text simple-btn{{showScheduleSlider ? '-active' : '' }}"></button>
                    
                    <!-- <img src="../../../assets/icons-svg/pngwing.com.png" alt="Teams" *ngIf="stage.type == 'Interview' || stage.type == 'OfflineInterview'" (click)="teamsScheduleSlider = true" title="Teams Schedule" style="width: 20px; height: 20px;"> -->

                    <button pButton pRipple type="button" icon="pi pi-refresh" (click)="getCandidates()" title="Refresh"
                        class="p-button-text simple-btn{{loadingSpinner ? '-active' : '' }}"></button>
                    <p-divider layout="vertical"></p-divider>
                    <label>Total List: <span [style]="{color: '#333333'}">{{ totalLength
                            }}</span></label>
                </span>
                <span class="table-menu">
                    <p [style]="{paddingRight: '10px'}">View</p>
                    <p-dropdown [options]="pageLimits" [(ngModel)]="pageSize"
                        [style]="{ maxWidth: '5rem', fontSize: '0.9rem' }" (onChange)="getUsersByLimit()">
                    </p-dropdown>
                    <p-divider layout="vertical"></p-divider>
                    <button pButton class="disabled p-button-secondary p-button-text simple-btn">
                        {{ first }} to {{ last }}
                    </button>
                    <button pButton class="p-button-secondary p-button-text simple-btn" (click)="prev()"
                        [disabled]="isFirstPage()" icon="pi pi-angle-left"></button>
                    <button pButton class="p-button-secondary p-button-text simple-btn" (click)="next()"
                        [disabled]="isLastPage()" icon="pi pi-angle-right"></button>
                </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr class="table-heading">
                <th pFrozenColumn scope="col" [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th [style]="{width: '12vw'}" scope="col" pSortableColumn="candidateId" pResizableColumn
                    pFrozenColumn>
                    Candidate ID
                    <p-sortIcon class="sort-icon" field="candidateId"></p-sortIcon>
                </th>
                <th [style]="{width: '12vw'}" scope="col" pSortableColumn="candidateName" pResizableColumn
                    pFrozenColumn>
                    Candidate Name
                    <p-sortIcon class="sort-icon" field="candidateName"></p-sortIcon>
                </th>
                <th *ngFor="let user of columns" pResizableColumn [style]="{width: '13vw'}" scope="col"
                    pSortableColumn="{{ user.field != 'testScore' ? user.field : false}}">
                    {{ user.header }}
                    <p-sortIcon class="sort-icon" field="{{ user.field }}"></p-sortIcon>
                </th>
            </tr>
            <tr class="selection-operations-row" *ngIf="selectedUsersList.length">
                <td pFrozenColumn class="selection-operations-options">
                    <span>{{selectedAllStageCandidates ? totalLength : selectedUsersList.length}} Selected</span>
                    <span class="hyperlink" [style]="{padding: '0 6px'}" *ngIf="!selectedAllStageCandidates && pageSize == selectedUsersList.length && stage.type == 'Test'" (click)="selectedAllStageCandidates = true">Select all {{totalLength}} candidates</span>
                    <span class="hyperlink" [style]="{padding: '0 6px'}" *ngIf="selectedAllStageCandidates" (click)="selectedAllStageCandidates = false; selectedUsersList = []">Clear Selection</span>
                    <div>
                        <button *ngIf="stageList.length" pButton pRipple [disabled]="selectedAllStageCandidates" label="Move to" (click)="stagesOP.toggle($event)"></button>
                        <button pButton pRipple *ngIf="stage.type == 'Offer'" label="Send Offer" (click)="sendOfferMail = true; sendMailHeader = 'Send Offer'; sendEmail();"></button>
                        <button pButton pRipple [disabled]="selectedAllStageCandidates" *ngIf="stage.type == 'Screening'" label="Send Documentation Link" (click)="sendDocumentation = true; sendMailHeader = 'Send Documentation'; sendEmail();"></button>
                        <button pButton pRipple [disabled]="selectedAllStageCandidates" label="Send Email" (click)=" sendMailHeader = 'Send Email'; sendEmail()"></button>
                        <button pButton pRipple *ngIf="stage.type == 'Interview' && interviewPlatform == 'teams'" [disabled]="selectedUsersList.length > 1 || selectedAllStageCandidates" 
                            (click)="isFeedbackSelected && isTemplateSelected?.selected && teamsScheduleSlider = true"
                            (mouseenter)="!isFeedbackSelected || !isTemplateSelected?.selected ? noInterview_op.toggle($event): ''"
                            (mouseleave)="noInterview_op.hide()">
                            <img alt="Teams" src="../../../assets/icons-svg/pngwing.com.png" style="width: 20px; padding-right: 3px;"/>
                            <span style="font-weight: 500;">Teams Schedule</span>
                        </button>
                        <button pButton pRipple *ngIf="stage.type == 'Interview' && interviewPlatform != 'teams'" label="Send Interview Link" 
                            (click)="isFeedbackSelected && isTemplateSelected?.selected && sendLinkSlider = true" [disabled]="selectedAllStageCandidates" 
                            (mouseenter)="!isFeedbackSelected || !isTemplateSelected?.selected ? noInterview_op.toggle($event): ''"
                            (mouseleave)="noInterview_op.hide()"></button>
                        <button pButton pRipple *ngIf="stage.type == 'OfflineInterview'" label="Schedule Interview"
                            (click)="isFeedbackSelected && isTemplateSelected?.selected && sendLinkSlider = true" [disabled]="selectedAllStageCandidates" 
                            (mouseenter)="!isFeedbackSelected || !isTemplateSelected?.selected ? noInterview_op.toggle($event): ''"
                            (mouseleave)="noInterview_op.hide()"></button>
                        <button pButton pRipple *ngIf="stage.type == 'Test'" label="Send Test Link"
                            (click)="isTestSelected && isTemplateSelected?.selected && sendTestLink(selectedUsersList)"
                            (mouseenter)="!isTestSelected || !isTemplateSelected?.selected ? noTest_op.toggle($event): ''"
                            (mouseleave)="noTest_op.hide()"></button>
                        <p-overlayPanel #noInterview_op>
                            <ng-template pTemplate>
                                <div
                                    [style]="{display: 'flex', width: '310px', padding: '10px 16px', boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.06)'}">
                                    <em class="info-button"></em>
                                    <p [style]="{overflowWrap: 'break-word', color: 'var(--fc-dark)', padding: '2px'}">
                                        Kindly select Feedback Form and Mail Template from Rules.
                                    </p>
                                </div>
                            </ng-template>
                        </p-overlayPanel>
                        <p-overlayPanel #noTest_op>
                            <ng-template pTemplate>
                                <div
                                    [style]="{display: 'flex', width: '310px', padding: '10px 16px', boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.06)'}">
                                    <em class="info-button"></em>
                                    <p [style]="{overflowWrap: 'break-word', color: 'var(--fc-dark)', padding: '2px'}">
                                        Kindly Schedule a test and select Mail Template from Rules.
                                    </p>
                                </div>
                            </ng-template>
                        </p-overlayPanel>
                    </div>
                    <div *ngFor="let status of statusOptions" class="status-btns">
                            <button pButton pRipple [label]="status.label" (click)="changeStatusSlider(status)" [disabled]="selectedAllStageCandidates" 
                                *ngIf="!status.disable"></button>
                    </div>
                </td>
                <p-overlayPanel #stagesOP>
                    <ng-template pTemplate>
                        <div class="stage-overlay" style="overflow-y: scroll; height: 120px;">
                            <div class="stageList">
                                <p *ngFor="let stage of stageList" class="stageList-options"
                                    (click)="moveStageSlider(stage, stagesOP)">{{stage.name}}
                                </p>
                            </div>
                        </div>
                    </ng-template>
                </p-overlayPanel>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-user let-columns="columns">
            <tr class="row-candidate-list" [style]="{cursor: 'pointer'}">
                <td [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
                    <p-tableCheckbox [value]="user"></p-tableCheckbox>
                </td>
                <td class="user-id_highlight" (click)="showCandidateDetails(user)" [style]="{width: '12vw'}" pFrozenColumn>{{
                    user != null ? user.id    : '-' }}</td>
                <td class="user-id_highlight" (click)="showCandidateDetails(user)" [style]="{width: '12vw'}" pFrozenColumn>{{
                    user != null ? user.name : '-' }}</td>
                <td *ngFor="let col of columns" [style]="{width: '13vw'}" [pEditableColumn]="'status'">

                    <span [ngSwitch]="true">
                        <span *ngSwitchCase="col.field === 'status'">
                            <p-cellEditor class="editStatus" (click)="setStatusDropdown(user[col.field])">
                                <ng-template pTemplate="input">
                                    <p-dropdown [options]="statusOptions" optionLabel="label" optionDisabled="disable"
                                        [(ngModel)]="currentStatus" (onChange)="changeStatusSlider($event.value, user)">
                                    </p-dropdown>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span class="status-tag {{ user[col.field] }}-tag">
                                        {{candidateStatus[user[col.field]]}}
                                        <!-- {{candidateStatus[user[col.field]]}} -->
                                    </span>
                                </ng-template>
                            </p-cellEditor>
                        </span>

                        <span *ngSwitchCase="col.field === 'testScore'" class="test_score_op"
                            [style]="{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}">
                            <div *ngIf="user[col.field].length; else noScore">
                                <span [style]="{display: 'flex', maxWidth: '6vw'}">
                                    <p *ngIf="user[col.field][0].stageId == col.stageId" style="font-weight: 500;color: black;">As {{user[col.field][0].attempts}},
                                        {{user[col.field][0].marks.toFixed(2)}} <span
                                            style="color: #666666;">/{{user[col.field][0].total_marks}}</span> </p>
                                </span>
                                <div *ngIf="user[col.field].length > 1" class="remainingItems-badge">
                                    <p-badge value="+{{user[col.field].length - 1}}" styleClass="mr-2"
                                        (mouseenter)="test_score_op.show($event)"></p-badge>
                                </div>
                            </div>
                            <ng-template #noScore><span [style]="{width: '6vw'}">
                                    <p [style]="{margin: '0 1.5vw'}">-</p>
                                </span></ng-template>
                            <p-overlayPanel #test_score_op [dismissable]="true">
                                <ng-template pTemplate>
                                    <div class="remainingItems" (mouseenter)="test_score_op.show($event)" (mouseleave)="test_score_op.hide()"> 
                                        <span *ngFor="let score of user[col.field].slice(1)" [style]="{display: 'flex', maxWidth: '10vw',width:'8rem',padding: '10px'}">
                                            <p *ngIf="score.stageId == col.stageId" style="font-weight: 500;color: black;"> As {{score.attempts}},
                                                {{score.marks.toFixed(2)}} <span style="color: #666666;">/{{score.total_marks}}</span> </p>
                                        </span>
                                    </div>
                                </ng-template>
                            </p-overlayPanel>
                            <p-divider [style]="{margin: '10px'}" layout="vertical"></p-divider>
                            <button pButton title="View Score" class="p-button-text simple-btn testScore" icon="pi pi-chart-bar"
                                (click)="getResultAnalysis(user, col.stageId)"></button>
                        </span>

                        <span *ngSwitchCase="col.field === 'isDocumentsSubmitted'">
                            <span>{{ user[col.field] ? 'Submitted' : 'Not Submitted' }}</span>
                        </span>
                        
                        <span *ngSwitchCase="col.field === 'feedbackStatus'">
                            <span>{{ user[col.field] ? user[col.field] : 'Feedback not Submitted' }}</span>
                        </span>

                        <span [style]="{fontSize: '12px'}" *ngSwitchCase="col.field === 'stageId'">

                            <div [ngSwitch]="user['status']">

                                <span *ngFor="let status of statusColor">
                                    <span *ngSwitchCase="status.status">
                                        <round-progress class="round-progress" [radius]="32" [stroke]="6"
                                            [background]="'#DFE3E7'" [current]="stage._stageId" [max]="stages.length"
                                            [rounded]="true" [semicircle]="true" [color]="status.color">
                                        </round-progress>
                                    </span>
                                </span>
                            </div>

                            <div [style]="{color: 'black', margin: '-15px 0 0 22px'}">
                                {{stage._stageId}}/{{stages.length}}
                            </div>
                        </span>

                        <span *ngSwitchCase="col.field === 'cohort_name'">
                            <span>{{ user.userCohortData[col.field] || '-'}}</span>
                        </span>
                        <span *ngSwitchCase="col.field === 'cohort_date'">
                            <span>{{ user.userCohortData.date | date: dateFormat || '-'}}</span>
                        </span>
                        <span *ngSwitchCase="col.field === 'cohort_spoc'">
                            <span>{{ user.userCohortData[col.field]?.name || '-'}}</span>
                        </span>
                        <span *ngSwitchCase="col.field === 'cohortJobRole' && stage.type == 'Offer'">
                            <span>{{ user.userCohortData[col.field] ? (user.userCohortData[col.field] === 'Nil' ? '-' : user.userCohortData[col.field]) : '-'}}</span>
                        </span>
                        <span *ngSwitchCase="col.field === 'cohortLocation' && stage.type == 'Offer'" class="cohortLocaiton">
                            <span>{{ user.userCohortData[col.field] ? user.userCohortData[col.field][0] : '-'}}
                                <div *ngIf="user.userCohortData[col.field]?.length > 1" class="remainingItems-badge">
                                    <p-badge value="+{{user.userCohortData[col.field]?.length - 1}}" styleClass="mr-2"
                                      (mouseenter)="events_op.show($event)"></p-badge>
                                </div>
                            </span>
                            <p-overlayPanel #events_op [dismissable]="true">
                                <ng-template pTemplate>
                                  <div class="remainingItems" (mouseenter)="events_op.show($event)" (mouseleave)="events_op.hide()">
                                    <p *ngFor="let event of user.userCohortData[col.field].slice(1)">{{event}}</p>
                                  </div>
                                </ng-template>
                              </p-overlayPanel>
                        </span>

                        <span *ngSwitchCase="col.field === 'dob'">
                            {{user[col.field] ? (user[col.field] | date: dateFormat) : '-'}}
                        </span>

                        <span *ngSwitchDefault>
                            {{user[col.field] ? user[col.field] : '-'}}
                        </span>

                    </span>

                </td>
            </tr>
        </ng-template>
    </p-table>
    <div *ngIf="displayUserDetails">
        <p-sidebar class="candidateDetaile" [transitionOptions]="'0ms'" [(visible)]="displayUserDetails"
            styleClass="p-sidebar-lg" position="right" [style]="{width: '921px'}">
            <app-candidate-info [selectedUser]="selectedUser" [numberOfStages]="stages.length" [disableActivity]="false" [disableStage]="false" [disableFeedback]="false" [statusSettings]="statusSettings" [stage]="stage"></app-candidate-info>
        </p-sidebar>
    </div>

    <p-sidebar *ngIf="sendEmailSlider" class="sendEmail-slider" (onHide)="sendOfferMail = false" [(visible)]="sendEmailSlider" styleClass="p-sidebar-lg"
        [showCloseIcon]="true" position="right" [style]="{width: '68rem'}">
        <app-send-email [selectedUsersList]="selectedUsersList" [assignCohortData]="sendOfferMail" [sendMailHeader]="sendMailHeader" [sendDocumentation]="sendDocumentation" (mailSent)="mailSent($event)"></app-send-email>
    </p-sidebar>
    <p-toast></p-toast>
</div>