import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
@Injectable({
  providedIn: 'root',
})
export class GlobalServicesService {
  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  encryptKey = environment.ENCRYPTION.ENCRYPTION_KEY;
  privilegeDetails: any = {};
  privilegeRoles: string = '';
  pageNumber: number = 0;
  pageSize: number = 0;
  searchTerm: string = '';
  talentTerm: string = '';
  status: string = 'All';
  candidateStatus: string = 'Active'
  // public dateFormat = new Subject<any>();
  // public timeFormat = new Subject<any>();
  public searchList = new Subject<any>();
  public searchCandidateList = new Subject<any>();
  public schoolData = new Subject<any>();
  bucketName: string = '';
  assetBucketName: string = '';

  constructor(private http: HttpClient, private breadcrumbService: NgDynamicBreadcrumbService) { 
    let nodeEnv = environment.HOST.NODE_ENV;
    if(nodeEnv === 'dev' || nodeEnv === 'acc')
    {
      this.bucketName = 'dev-neo-hire';
      this.assetBucketName = 'dev-neo-hire-asset'
    }
    else if(nodeEnv === 'prod')
    {
      this.bucketName = 'prod-neo-hire';
      this.assetBucketName = 'prod-neo-hire-asset'
    }
    
  }

  options() {
    let href = 'admin.' + window.location.href.split('.')[1] + '.exam.ly';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      origin: href,
    });

    return { headers };
  }

  mongoOptions() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      origin: window.location.href,
      Authorization: localStorage.getItem('token') ?? '',
      schoolid: schoolId,
    });
    return { headers };
  }

  //get school Data
  getSchoolData(): Observable<any> {
    return this.http.get(this.host_api_url + '/school/hire/login', this.options());
  }

  getSchoolDetails(): Observable<any> {
    return this.http.get(this.api_url + '/schools', this.mongoOptions());
  }

  getCurentPageUrl() {
    return window.location.pathname;
  }

  loadScript(
    element: string,
    url: string,
    type: string,
    id: string,
    child?: any[],
    defer?: any,
    onload?: any,
    onerror?: any
  ): Promise<any> {
    return new Promise(async (resolved, rejected) => {
      if (document.getElementById(id)) {
        resolved(true);
      } else {
        const node: any = document.createElement(element);
        if (element === 'link') {
          node.href = url;
          node.rel = 'stylesheet';
        } else if (element === 'script') {
          node.src = url;
        }

        if (defer) {
          node.defer = defer;
        }

        if (onload) {
          node.onload = resolved(onload);
        }

        if (onerror) {
          node.onerror = resolved(onerror);
        }
        node.type = type;
        node.id = id;
        document.getElementsByTagName('head')[0].appendChild(node);
        node.onload = () => {
          if (child && child.length) {
            const promiseArray: any[] = [];
            child.forEach((script: any) => {
              promiseArray.push(
                this.loadScript(
                  script.element,
                  script.url,
                  script.type,
                  script.rel,
                  script.id,
                  script.child
                )
              );
            });
            Promise.all(promiseArray).then(() => {
              resolved(true);
            });
          } else {
            resolved(true);
          }
        };
        node.onerror = () => {
          rejected(false);
        };
      }
    });
  }

  removeScript() {
    let script = document.getElementById("examlyck")
    if (script) {
      script.remove();
    }
  }

  sso(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/users/ssoLogin', payload, this.mongoOptions());
  }

  getUserPrivileges(roleType: string): Observable<any> {
    return this.http.get(this.api_url + '/userPrivileges/getPrivileges/' + roleType, this.mongoOptions());
  }

  getResultAnalysis(payload: any, c_id: any, t_id: any, email: any): Observable<any> {
    return this.http.post(this.api_url + `/users/getResultAnalysis/${c_id}/${t_id}?email=${email}`, payload, this.mongoOptions());
  }

  getScheduleTest(email: any, jobId: any, stageId: any): Observable<any> {
    return this.http.get(this.api_url + `/test/${email}/${jobId}/${stageId}`, this.mongoOptions());
  }

  decryptPrivileges() {
    let data: any = localStorage.getItem("userPrivilege");
    if (!data) {
      return false;
    }
    let _key = CryptoJS.enc.Utf8.parse(this.encryptKey);
    let _iv = CryptoJS.enc.Utf8.parse(this.encryptKey);
    let decrypted: any = CryptoJS.AES.decrypt(
      data, _key, {
      keySize: 256,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
  }

  getSearchEvents(searchTerm: string): Observable<any> {
    let userDetails: any = localStorage.getItem('userDetails');
    userDetails = JSON.parse(userDetails);
    return this.http.get(this.api_url + `/event/search/events?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&status=${this.status.toLocaleLowerCase()}&eventTitle=${searchTerm}&userId=${userDetails.id}`, this.mongoOptions());
  }

  getSearchCandidates(searchTerm: string, payload: any, status: string): Observable<any> {
    return this.http.post(this.api_url + '/talentPool/allCandidates/search?pageNumber=' + this.pageNumber + '&pageSize=' + this.pageSize + '&search=' + searchTerm + '&fieldName=email&status=' + status, payload, this.mongoOptions());
  }

  getOpenIde(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/column/openIDE', payload, this.mongoOptions());
  }

  updateEmailConfig(payload: any): Observable<any> {
    return this.http.put(this.api_url + '/schools/updateEmailConfig', payload, this.mongoOptions());
  }

  updateBreadcrumb(label: string): void {
    const breadcrumbs = [
      {
        label: label,
        url: ''
      }
    ];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }

}

