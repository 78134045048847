import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';
import { EventServicesService } from 'src/app/services/event-services.service';
import { SharedServicesService } from 'src/app/services/shared-services.service';
import { Router } from '@angular/router';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.css']
})
export class EventsListComponent implements OnInit {

  eventsList: Array<any> = [];
  pageLimits: Array<any> = [];
  status: MenuItem[] = [];
  eventsCol: Array<any> = []
  _selectedColumns: Array<any> = []
  selectedEvents: Array<any> = [];
  _eventsCol: Array<any> = [];
  colsToDownload: Array<any> = [];
  columnsToSave: Array<any> = [];
  savedColumnSettings: Array<any> = [];
  checkedColumns: Array<any> = [];

  addEventSlider: boolean = false
  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showSettingsSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  displaySaveFilterSettings: boolean = false;
  displaySaveColumnSettings: boolean = false;

  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;

  innerHeight: string = '';
  statusSelected: string = 'All';
  columnSearchTerm: string = '';
  filterSearchTerm: string = '';
  downloadSearchTerm: string = '';
  columnFieldId: string = '';
  downloadAs: string = 'excel';
  exportName: string = 'EventList';
  privilege: any = {};
  userRole: string = '';
  dateFormat: any;


  constructor(private eventServices: EventServicesService, private _sharedService: SharedServicesService, private _router: Router, private globalService: GlobalServicesService) {
    this.innerHeight = String(window.innerHeight * 0.7);
    this.pageLimits = [10, 25, 50, 100];
    this.status = [
      {
        label: 'All',
        command: () => {
          this.getEventsByFilter('All');
        },
      },
      {
        label: 'Open',
        command: () => {
          this.getEventsByFilter('open');
        },
      },
      {
        label: 'Saved',
        command: () => {
          this.getEventsByFilter('saved');
        },
      },
    ];

    this.eventsCol = [
      { index: 1, field: 'jobRole', header: 'Profile / Role' },
      { index: 2, field: 'status', header: 'Event Status' },
      { index: 3, field: 'candidateCount', header: 'Total Count' },
      { index: 4, field: 'eligible', header: 'Eligible' },
      { index: 5, field: 'notEligible', header: 'Not Eligible' },
      { index: 6, field: 'institutionName', header: 'Institution Name' },
      { index: 7, field: 'institutionAddress', header: 'Institution Address' },
      { index: 8, field: 'createdBy', header: 'Created by' },
      { index: 9, field: 'eventStartDate', header: 'Event Start Date' },
      { index: 10, field: 'eventEndDate', header: 'Event End Date' },
      { index: 11, field: 'registrationStartDate', header: 'Registration Start Date' },
      { index: 12, field: 'registrationEndDate', header: 'Registration End Date' },
      { index: 13, field: 'createdAt', header: 'Created Date' },
    ]

    this._selectedColumns = [
      { index: 1, field: 'jobRole', header: 'Profile / Role' },
      { index: 2, field: 'status', header: 'Event Status' },
      { index: 3, field: 'candidateCount', header: 'Total Count' },
      { index: 4, field: 'eligible', header: 'Eligible' },
      { index: 5, field: 'notEligible', header: 'Not Eligible' },
      { index: 6, field: 'institutionName', header: 'Institution Name' },
      { index: 7, field: 'institutionAddress', header: 'Institution Address' },
      { index: 8, field: 'createdBy', header: 'Created by' },
      { index: 9, field: 'eventStartDate', header: 'Event Start Date' },
      { index: 10, field: 'eventEndDate', header: 'Event End Date' }
    ]
  }

  columnSettings: FormGroup = new FormGroup({
    columnName: new FormControl(''),
    columnValue: new FormControl(this.columnsToSave)
  })

  setGlobalStatus(status: string) {
    this.globalService.status = status
  }

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].dateFormat : '';
    // this.globalService.dateFormat.subscribe((res: any) => {
    //   this.dateFormat = res;
    // })
    this._eventsCol = this.eventsCol;
    this.colsToDownload = this.eventsCol;
    this.checkedColumns = this._selectedColumns;
    this.privilege = this.globalService.privilegeDetails;
    this.userRole = this.globalService.privilegeRoles;
    if (!this.userRole) {
      this.userRole = JSON.parse(localStorage.getItem('userDetails') || '{}').userType;
    }
    this.getAllEvents();
    this.getColumnId();
    this.getSavedColumns();
    this.globalService.searchList.subscribe((res) => {
      let response = JSON.parse(JSON.stringify(res))
      this.eventsList = [];
      this.eventsList = response.data ? response.data.items : [];
      this.totalLength = response.data ? response.data.count : 0;
      this.eventsList.forEach((eachData) => {
        eachData.eligible = eachData.candidateCount?.eligibleCount;
        eachData.notEligible = eachData.candidateCount?.notEligibleCount;
        eachData.candidateCount = eachData.candidateCount?.appliedCount;
      })
    })
    this.globalService.updateBreadcrumb("Manage Events");
  }

  getAllEvents = () => {
    this.loadingSpinner = true;
    this.globalService.pageNumber = this.pageNumber;
    this.globalService.pageSize = this.pageSize;
    if (this.globalService.searchTerm.length) {
      this.globalService.getSearchEvents(this.globalService.searchTerm).subscribe((res) => {
        this.globalService.searchList.next(res);
        this.loadingSpinner = false;
      })
    }
    else {
      this.eventServices.getAllEvents(this.pageNumber, this.pageSize, this.userRole).subscribe(response => {
        this.eventsList = response.data ? response.data.items : [];
        this.eventsList.forEach((eachData) => {
          eachData.eligible = eachData.candidateCount?.eligibleCount;
          eachData.notEligible = eachData.candidateCount?.notEligibleCount;
          eachData.candidateCount = eachData.candidateCount?.appliedCount;
        })
        this.totalLength = response.data ? response.data.totalItems : 0;
        this.loadingSpinner = false;
      })
      
    }
  }

  getEventsByLimit = () => {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.statusSelected === 'All' ? this.getAllEvents() : this.getFilterEvents(this.statusSelected);
  }

  getEventsByFilter = (status: string) => {
    this.setGlobalStatus(status);
    this.statusSelected = status;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    status === 'All' ? this.getAllEvents() : this.getFilterEvents(status);
  };

  getFilterEvents = (status?: string) => {
    this.loadingSpinner = true;
    this.eventsList = [];
    this.globalService.pageNumber = this.pageNumber;
    this.globalService.pageSize = this.pageSize;
    if (this.globalService.searchTerm.length) {
      this.globalService.getSearchEvents(this.globalService.searchTerm).subscribe((res) => {
        this.globalService.searchList.next(res);
        this.loadingSpinner = false;
      })
    }
    else {
      this.eventServices
        .getFilterEvents(this.pageNumber, this.pageSize, status)
        .subscribe((response: any) => {
          this.eventsList = response.data ? response.data.items : [];
          this.eventsList.forEach((eachData) => {
            eachData.eligible = eachData.candidateCount?.eligibleCount;
            eachData.notEligible = eachData.candidateCount?.notEligibleCount;
            eachData.candidateCount = eachData.candidateCount?.appliedCount;
          })
          this.totalLength = response.data ? response.data.totalItems : 0;
          this.loadingSpinner = false;
        });
    }
  };

  saveColumnSettings = () => {
    this.loadingSpinner = true;
    this.columnsToSave = this.checkedColumns.map(e => e.field);

    this.columnSettings.setValue({
      columnName: this.columnSettings.get("columnName")?.value,
      columnValue: this.columnsToSave
    });

    this.eventServices.saveColumns(this.columnFieldId, [this.columnSettings.value]).subscribe((result: any) => {
      this.savedColumnSettings = result.data.eventSelectedColumns;
      this.loadingSpinner = false;
    });
    this.displaySaveColumnSettings = false;
  }

  getColumnId = () => {
    this.eventServices.getSchool().subscribe(res => {
      this.columnFieldId = res.data[0].id;
    })
  }

  getSavedColumns = () => {
    this.eventServices.getSchool().subscribe(res => {
      this.savedColumnSettings = res.data[0].eventSelectedColumns ? res.data[0].eventSelectedColumns : []
    });
  }

  deleteSavedColumns = (columnId: string) => {
    this.loadingSpinner = true;
    this.eventServices.deleteColumns(this.columnFieldId, columnId).subscribe(res => {
      this.savedColumnSettings = res.data.eventSelectedColumns;
      this.loadingSpinner = false;
    })
  }

  deleteEvent = (event: any) => {
    this.loadingSpinner = true;
    this.eventServices.deleteEvent(event.id).subscribe(res => {
      this.selectedEvents = [];
      this.getAllEvents();
    })
  }

  searchColumns = (searchTerm: string) => {
    if (this.showSettingsSidebar) {
      this._eventsCol = this.eventsCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (this.showDownloadSidebar) {
      this.colsToDownload = this.eventsCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }

  setStatus = (status: string) => {
    this.statusSelected = status;
  }

  getEventVal = (event: Event) => {
    return (event.target as HTMLInputElement).value;
  }

  onSelectedEventChange = (value: []) => {
    this.selectedEvents = value;
  }

  isStringsArray(array: any) {
    return array.every((element: any) => typeof element === "string");
  }

  exportEvent = (fileType?: string) => {
    let checkedColumnFields: any = ['eventTitle'];
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    this.eventsList.forEach((eachData: any) => {
      let filterData: any = {};
      checkedColumnFields.forEach((item: any) => {
        if(eachData.hasOwnProperty(item)) {
          if(Array.isArray(eachData[item]) && this.isStringsArray(eachData[item])){
            filterData[item] = eachData[item].join(", ");
          }
          else
          {
            filterData[item] = eachData[item];
          }
        }
      });
      if (
        checkedColumnFields.find((element: string) => element === 'jobRole')
      ) {
        let jobRoles: any = [];
        eachData.jobRole.forEach((eachjobRole: any) => {
          jobRoles.push(eachjobRole.roleName);
        });
        filterData['jobRole'] = jobRoles.join(", ");
      }
      afterFilter.push(filterData);
    });
    console.log("afterFilter ->", afterFilter)
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, this.exportName, fileType);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string, type?: string): void {
    let FILE_TYPE = type === 'Excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' : 'text/csv';
    let FILE_EXTENSION = type === 'Excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  applySelectedColumns = () => {
    this._selectedColumns = this.sortedColumns();
    this.showSettingsSidebar = false;
  }

  clearSelectedColumns = () => {
    this.checkedColumns = [];
  }

  applySavedSettings = (savedColumnValue: Array<any>) => {
    this.checkedColumns = this.eventsCol.filter(e => savedColumnValue.includes(e.field));
  }

  openCandidates = (eventName: string, eventId: string, category: string = 'technical') => {
    window.localStorage.setItem('selectedEventId', eventId);
    window.localStorage.setItem('selectedEventName', eventName);
    window.localStorage.setItem('selectedJobId', eventId);
    window.localStorage.setItem('eventCategory', category);
    this._router.navigate(['events/candidates/', eventName], { queryParams: { view: 'candidates' } });
  }

  private sortedColumns(): any[] {
    return this.checkedColumns.sort((a, b) => (a.index < b.index) ? -1 : 1);
  }

  next(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first + this.pageSize;
    this.last = this.last + this.pageSize < this.totalLength ? this.pageSize + this.last : this.totalLength;
    this.pageNumber += 1;
    this.getAllEvents();
  }

  prev(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize > this.pageSize ? ( this.last == this.totalLength ? this.totalLength - this.eventsList.length : this.last - this.pageSize ) : this.pageSize;
    this.pageNumber -= 1;
    this.getAllEvents();
  }

  isLastPage(): boolean {
    return this.totalLength ? this.first > this.totalLength - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.first > 1 ? false : true;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.eventsCol.filter(col => val.includes(col));
  }

}
