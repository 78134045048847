<div class="workflow-container">
    <div class="workflow-content">
        <div *ngIf="isLoading" class="loadingOverlay">
            <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
            </p-progressSpinner>
        </div>
        <div class="stages-list">
            <div class="{{menu.isActive ? 'active' : ''}} {{menu.view ? 'stage': ''}}"
                *ngFor="let menu of sideMenuItems" (click)="openSideMenu(menu.id)">
                <p *ngIf="menu.view">{{ menu.name }}</p>
            </div>
        </div>

        <div class="stages-container">
            <div class="header-tab_search">
                <div style="display: flex; align-items: center;">
                    <div class="header-tabs" *ngIf="selectedMenuID == 'ongoing'" style="margin-bottom: 10px;">
                        <button class="tabs" (click)="splitLobbyCandidate('lobby')" 
                        [ngClass]="{'selected': displayLobbyCandidates}">Lobby</button>
                        <button class="tabs" (click)="splitLobbyCandidate('live')" 
                        [ngClass]="{'selected': displayLiveCandidates}">Ongoing</button>
                    </div>
                    <div class="header-tabs" *ngIf="selectedMenuID == 'calendarInvites'" style="margin-bottom: 10px;">
                        <button class="tabs" (click)="getCalendarInvites('scheduled')" 
                            [ngClass]="{'selected': selectedCalendarStatus == 'scheduled'}">Scheduled</button>
                        <button class="tabs" (click)="getCalendarInvites('completed')" 
                            [ngClass]="{'selected': selectedCalendarStatus == 'completed'}">Completed</button>
                    </div>
                </div>

                <div class="stage-search">
                    <span class="p-input-icon-left">
                        <em class="pi pi-search"></em>
                        <input class="searchbar" [(ngModel)]="searchEmailList"
                            (keyup.enter)="openSideMenu(selectedMenuID, $event)" type="text" pInputText
                            placeholder="Search Email" />
                    </span>
                </div>
                
            </div>
            <div class="table" [style]="{display: 'flex', background: '#F5F5F5'}">


                <!-- Column Settings Sidebar -->
                <p-sidebar class="settingsSidebar candidateSidebar" [transitionOptions]="'0ms'"
                    [(visible)]="showSettingsSidebar" [style]="{ padding: '0', top: '0 !important'}" [modal]="false">
                    <p class="sidebar-title">Column Settings</p>
                    <div
                        class="{{(savedColumnSettings && savedColumnSettings.length) ? 'savedColumns-continer' : 'hidden'}}">
                        <p>Saved Column</p>
                        <div class="savedColumns" *ngFor="let column of savedColumnSettings"
                            (click)="applySavedSettings(column.columnValue)">
                            <p>{{column.columnName}}</p>
                            <em class="pi pi-trash" (click)="deleteSavedColumns(column.columnId)"></em>
                        </div>
                    </div>
                    <span class="p-input-icon-left">
                        <em class="pi pi-search"></em>
                        <input type="text" pInputText placeholder="Search" [(ngModel)]="columnSearchTerm"
                            (ngModelChange)="searchColumns($event)" />
                    </span>
                    <div *ngFor="let col of _usersCol" class="field-checkbox">
                        <p-checkbox [disabled]="col.index == 1 || col.index == 2" name="category" [value]="col"
                            [(ngModel)]="checkedColumns" [inputId]="col.field"></p-checkbox>
                        <label [for]="col.field" [style]="{paddingLeft: '10px'}">{{col.header}}</label>
                    </div>

                    <div class="sidebar-bottom">
                        <div class="sidebar-bottom-apply" [ngClass]="{'hidden' : displaySaveColumnSettings}">
                            <p *ngIf="false" class="sidebar-bottom-text" (click)="showSaveColumnSettings()">Save
                                Selected Columns</p>
                            <div class="sidebar-bottom-buttons">
                                <button pButton pRipple type="button" label="Clear" class="p-button-outlined"
                                    (click)="clearSelectedColumns()"></button>
                                <button pButton pRipple type="button" label="Apply"
                                    (click)="applySelectedColumns()"></button>
                            </div>
                        </div>
                        <div class="sidebar-saveSettings" [ngClass]="{'hidden' : !displaySaveColumnSettings}">
                            <em class="pi pi-times" (click)="displaySaveColumnSettings = false"></em>
                            <form class="p-inputgroup" [formGroup]="columnSettings" (ngSubmit)="saveColumnSettings()">
                                <input type="text" pInputText placeholder="Enter Filter Name"
                                    formControlName="columnName">
                                <button type="submit" pButton pRipple label="Save"></button>
                            </form>
                        </div>
                    </div>
                </p-sidebar>

                <!-- Filter Sidebar -->
                <p-sidebar class="filterSidebar candidateSidebar" [transitionOptions]="'0ms'"
                    [(visible)]="showFilterSidebar" [style]="{ padding: '0', top: '0 !important'}" [modal]="false"
                    [baseZIndex]="0" ariaCloseLabel="Filter">
                    <p class="sidebar-title">Filter</p>
                    <span class="p-input-icon-left">
                        <em class="pi pi-search"></em>
                        <input type="text" pInputText placeholder="Search">
                    </span>
                </p-sidebar>

                <!-- Candidate Details Sidebar -->
                <!-- <p-sidebar class="candidateDetaile" [transitionOptions]="'0ms'" [(visible)]="displayUserDetails"
                    styleClass="p-sidebar-lg" position="right" [style]="{width: '921px'}">
                    <app-candidate-info [selectedUser]="selectedUser"></app-candidate-info>
                </p-sidebar> -->

                <!-- Feedback Form Sidebar -->
                <p-sidebar model="true" class="addRole-slider" [style]="{width: '55rem'}" [(visible)]="feedbackSlider"
                    styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right"
                    (onHide)="viewFeedBack = false; editFeedback = false;createFeedBack = false; openSideMenu(selectedMenuID); calendarInvite = ''">
                    <div style="position:relative; padding: 20px 50px; background-color: #F8F8F8;">
                        
                        <app-feedback-form [headerData]="paramItems" [inEditMode]="editFeedback" [calendarInvite]="calendarInvite"
                            [inViewMode]="viewFeedBack" [createMode]="createFeedBack" [isOfflineInterview]="paramItems?.interviewType == 'offline' ? true : false"
                            [formName]="formName"></app-feedback-form>
                    </div>
                </p-sidebar>

                <!-- Download Sidebar -->
                <p-sidebar [transitionOptions]="'0ms'" class="downloadSidebar  candidateSidebar" [modal]="false"
                    [style]="{ padding: '0', top: '0 !important'}" [(visible)]="showDownloadSidebar">
                    <p class="sidebar-title">Download Data</p>

                    <span class="p-input-icon-left">
                        <em class="pi pi-search"></em>
                        <input pInputText (ngModelChange)="searchColumns($event)" [(ngModel)]="downloadSearchTerm"
                            placeholder="Search" type="text" />
                    </span>

                    <div *ngFor="let col of colsToDownload" class="field-checkbox">
                        <p-checkbox name="category" [value]="col" [(ngModel)]="checkedColumns"
                            [inputId]="col.field"></p-checkbox>
                        <label [for]="col.field" [style]="{paddingLeft: '10px'}">{{col.header}}</label>
                    </div>

                    <div class="download-container">
                        <p>Download as</p>
                        <div class="radioButton-group">
                            <div class="radioButton">
                                <p-radioButton [(ngModel)]="downloadAs" value="excel" name="exportButtons"
                                    inputId="excel">
                                </p-radioButton>
                                <label
                                    [style]="{color: downloadAs === 'excel' ? 'var(--fc-primary)' : 'var(--fc-light-dark)'}"
                                    for="excel"><em class="pi pi-file"></em>Excel</label>
                            </div>
                            <div class="radioButton">
                                <p-radioButton [(ngModel)]="downloadAs" name="exportButtons" inputId="csv" value="csv">
                                </p-radioButton>
                                <label
                                    [style]="{color: downloadAs === 'csv' ? 'var(--fc-primary)' : 'var(--fc-light-dark)'}"
                                    for="csv"><em class="pi pi-file-excel"></em>CSV</label>
                            </div>
                        </div>

                        <button class="p-button-raised" pRipple pButton type="button"
                            (click)="(downloadAs === 'excel') ? exportCandidate('excel') : exportCandidate('csv')"
                            label="Download"></button>
                    </div>

                </p-sidebar>

                <p-table #dt [columns]="_selectedColumns" [value]="candidateList" [loading]="loadingSpinner"
                    [scrollable]="true" scrollHeight="76vh" scrollDirection="both" responsiveLayout="scroll"
                    [resizableColumns]="true" [rows]="pageSize" dataKey="id" [exportFilename]="exportName"
                    [globalFilterFields]="['userName', 'status', 'stage', 'email']" [(selection)]="selectedUsersList"
                    [style]="{width: showFilterSidebar || showSettingsSidebar || showDownloadSidebar ? '50vw': '70vw'}"
                    (selectionChange)="handleSelection($event)">

                    <ng-template pTemplate="caption">
                        <div class="table-header">
                            <span class="table-header-left">
                                <button pButton
                                    (click)="showSettingsSidebar = true; showFilterSidebar = false; showDownloadSidebar = false"
                                    class="p-button-text simple-btn{{showSettingsSidebar ? '-active' : '' }}"
                                    icon="pi pi-sliders-h">
                                </button>
                                <!-- <button *ngIf="false" pButton
                                    (click)="showFilterSidebar = true; showSettingsSidebar = false; showDownloadSidebar = false"
                                    class="p-button-text simple-btn{{showFilterSidebar ? '-active' : '' }}"
                                    icon="pi pi-filter">
                                </button> -->
                                <button pButton
                                    (click)="showDownloadSidebar = true; showSettingsSidebar = false; showFilterSidebar = false"
                                    class="p-button-text simple-btn{{showDownloadSidebar ? '-active' : '' }}"
                                    icon="pi pi-download">
                                </button>
                                <button pButton pRipple type="button" icon="pi pi-refresh" (click)="tableRefresh()"
                                    title="Refresh"
                                    class="p-button-text simple-btn{{loadingSpinner ? '-active' : '' }}"></button>
                                <p-divider layout="vertical"></p-divider>
                                <label>Total List: <span [style]="{color: '#333333'}">{{ totalLength }}</span></label>
                            </span>
                            <span *ngIf="totalLength" class="table-menu">
                                <p [style]="{paddingRight: '10px'}">View</p>
                                <p-dropdown [options]="pageLimits" [(ngModel)]="pageSize"
                                    [style]="{ maxWidth: '5rem', fontSize: '0.9rem' }" (onChange)="getUsersByLimit()">
                                </p-dropdown>
                                <p-divider layout="vertical"></p-divider>
                                <button pButton class="disabled p-button-secondary p-button-text simple-btn">
                                    {{ first }} to {{ last }}
                                </button>
                                <button pButton class="p-button-secondary p-button-text simple-btn" (click)="prev()"
                                    [disabled]="isFirstPage()" icon="pi pi-angle-left"></button>
                                <button pButton class="p-button-secondary p-button-text simple-btn" (click)="next()"
                                    [disabled]="isLastPage()" icon="pi pi-angle-right"></button>
                            </span>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header" let-columns>
                        <tr class="table-heading">
                            <th pFrozenColumn scope="col"
                                [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <!-- <th [style]="{width: '15vw'}" scope="col" pSortableColumn="name" pResizableColumn pFrozenColumn>
                                Name
                                <p-sortIcon class="sort-icon" field="name"></p-sortIcon>
                            </th> -->
                            <th *ngFor="let user of columns" pResizableColumn [style]="{width: '14vw'}" scope="col"
                                pSortableColumn="{{ user.field }}">
                                {{ user.header }}
                                <p-sortIcon class="sort-icon" field="{{ user.field }}"></p-sortIcon>
                            </th>
                        </tr>
                        <tr class="selection-operations-row"
                            *ngIf="selectedUsersList.length && (selectedMenuID === 'allCandidates' || selectedMenuID === 'unassigned' )">
                            <td pFrozenColumn class="selection-operations-options">
                                <span style="margin-left: -14%;">{{selectedUsersList.length}} Selected</span>
                                <!-- <p-dropdown pRipple [options]="assigneeList" optionValue="userId" [autoDisplayFirst]="false" [(ngModel)]="numberOfassigne"
                                 style="margin-left: 5%;" (onChange)="candidateAssing($event)">
                                </p-dropdown> -->
                                <button pButton pRipple optionValue="userId" label="Assign" icon="pi pi-external-link"
                                    (click)="stagesOP.toggle($event)"></button>
                            </td>
                            <p-overlayPanel #stagesOP>
                                <ng-template pTemplate>
                                    <div class="stageList" *ngIf="assignSelected">
                                        <div *ngFor="let assign of assigneeList" class="stageList-options">
                                            <p (click)="candidateAssing(assign, stagesOP)">{{assign.label}}</p>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-overlayPanel>
                            <!-- <p-overlayPanel #stagesOP>
                                <ng-template pTemplate>
                                    <div class="stageList">
                                        <p *ngFor="let stage of stageList" class="stageList-options"
                                            (click)="moveCandidates(stage.stageId, stagesOP)">{{stage.name}}
                                        </p>
                                    </div>
                                </ng-template>
                            </p-overlayPanel> -->
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-user let-columns="columns">
                        <tr class="row-candidate-list" [style]="{cursor: 'pointer'}">
                            <td [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
                                <p-tableCheckbox [value]="user"></p-tableCheckbox>
                            </td>
                            <!-- <td [style]="{width: '15vw'}" pFrozenColumn (click)="showUserDetails(user.email)">{{ user.name }}</td> -->
                            <td (click)="showCandidateDetails(user, col)" *ngFor="let col of columns"
                                [style]="{width: '14vw'}">

                                <span [ngSwitch]="true">
                                    <span class="name" *ngSwitchCase="col.field === 'name'">{{user[col.field]}}</span>

                                    <span *ngSwitchCase="col.field === 'action' && selectedMenuID !== 'completed'">
                                        <button pButton class="joinBtn" (click)="user?.interviewType == 'offline' ? openOfflineInterview(user) : openJitsiContainer(user)"
                                            [label]="user?.interviewType == 'offline' ? 'Submit Feedback' : 'Join'"></button>
                                    </span>
                                    <span *ngSwitchCase="col.field === 'action' && selectedMenuID === 'completed'">
                                        <button pButton class="joinBtn" pTooltip="View Recording" *ngIf="user['interviewType'] == 'online' && user.recordedVideoLink; else noRecording"
                                            (click)="viewRecordedVideo(user.recordedVideoLink)" label="View"></button>
                                        <ng-template #noRecording>
                                            <span *ngIf="user['interviewType'] == 'online'">No Recording</span>
                                            <span *ngIf="user['interviewType'] == 'offline'">-</span>
                                        </ng-template>
                                    </span>

                                    <span *ngSwitchCase="col.field === 'status'">
                                        <span [ngSwitch]="user[col.field]">
                                            <span *ngSwitchCase="'InMeeting'">Live</span>
                                            <span *ngSwitchCase="'meetingEnd'">Completed</span>
                                            <span *ngSwitchDefault>{{user[col.field] ? user[col.field] : '-'}}</span>
                                        </span>
                                    </span>

                                    <span *ngSwitchCase="col.field === 'interviewType'">
                                        {{ user[col.field] == 'offline' ? 'Offline' : 'Online' }}
                                    </span>

                                    <span *ngSwitchCase="col.field === 'isFeedbackSubmitted'" class="feedBackCell">
                                        {{user[col.field] ? user[col.field] : selectedMenuID === 'completed' ? this.userType == 'recruiter' && this.userDetails.id !== user['recruiterId'] ? '-' :'' :
                                        '-'}}
                                        <p-overlayPanel #op>
                                            <ng-template pTemplate="content">
                                                <div class="feedback-options">
                                                    <p class="option-header">{{items[0].label}}</p>
                                                    <div *ngFor="let item of items[0].items">
                                                        <span class="option-item"
                                                            (click)="item?.command(); op.hide()"><i
                                                                [class]="item.icon"></i>{{item.label}}</span>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-overlayPanel>
                                        <button pButton *ngIf="(this.userType == 'admin' || this.userType == 'recruiter' || this.userType === 'hiringManager' ) && user[col.field]"
                                            (click)="userToEdit = user; op.toggle($event)" icon="pi pi-ellipsis-h"
                                            pTooltip="Options" class="p-button-text simple-btn">
                                        </button>
                                        <span *ngIf="this.userType === 'recruiter'; else openFeedbackTemplate">
                                            <span class="submit-feedback" (keydown)="openFeedback(user, 'create')" (click)="openFeedback(user, 'create')"
                                                *ngIf="(this.userDetails.id === user['recruiterId']) && selectedMenuID === 'completed' && !user[col.field]"
                                                pTooltip="Submit Feedback">
                                                <img src="../../../assets/icons-svg/Feedback.svg" alt="Submit Feedback">
                                            </span>
                                        </span>
                                        <ng-template #openFeedbackTemplate>
                                            <span class="submit-feedback" (keydown)="openFeedback(user, 'create')" (click)="openFeedback(user, 'create')"
                                                *ngIf="selectedMenuID === 'completed' && !user[col.field]"
                                                pTooltip="Submit Feedback">
                                                <img src="../../../assets/icons-svg/Feedback.svg" alt="Submit Feedback">
                                            </span>
                                        </ng-template>
                                        <!-- sideMenuItems[sideMenuItems.length - 1].isActive &&  -->
                                    </span>

                                    <span *ngSwitchCase="col.field === 'submittedCode'">
                                        <button *ngIf="user[col.field]; else noCode" pButton class="joinBtn" (click)="openViewCode(user)"
                                            label="</>" pTooltip="View Code"></button>
                                        <ng-template #noCode>{{ user['interviewType'] == 'offline' ? '-' : 'Not Submitted'}}</ng-template>
                                    </span> 

                                    <span *ngSwitchCase="col.field === 'attendees'">
                                        <span (click)="attendees.toggle($event)" class="hyperlink">
                                            {{user[col.field][0]?.name}} 
                                            <span *ngIf="user[col.field].length > 1" class="remainingItems-badge">
                                                <p-badge value="+{{user[col.field].length - 1}}" styleClass="mr-2"></p-badge>
                                            </span>
                                        </span>
                                        <p-overlayPanel #attendees [style]="{minWidth: '36rem', width: 'fit-content'}">
                                            <ng-template pTemplate>
                                                <p-table [value]="user[col.field]" selectionMode="single" responsiveLayout="scroll">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th pSortableColumn="name">Name<p-sortIcon field="name"></p-sortIcon></th>
                                                            <th>Email</th>
                                                            <th pSortableColumn="price">Type <p-sortIcon field="price"></p-sortIcon></th>
                                                            <th>Feedback</th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData let-attendee>
                                                        <tr [pSelectableRow]="rowData">
                                                            <td>{{attendee.name}}</td>
                                                            <td>{{attendee.email}}</td>
                                                            <td class="capitalize">{{attendee.type}}</td>
                                                            <td style="display: flex; justify-content : center;">
                                                                <span *ngIf="user['meetingStatus'] == 'scheduled'">
                                                                    <button pButton class="joinBtn" *ngIf="attendee.type == 'candidate'; else noFeedback" (click)="openFeedbackForm(attendee, user['id'])"
                                                                    [label]="'Submit Feedback'"></button>
                                                                </span>
                                                                <ng-template #noFeedback>-</ng-template>
                                                                <span *ngIf="user['meetingStatus'] == 'completed'"  class="feedBackCell">
                                                                    <span *ngIf="attendee.type == 'candidate'; else noFeedback" class="feedBackCell">
                                                                        Submitted 
                                                                        <button pButton (click)="setUserToEdit(attendee, user['id']); feedbackMenu.toggle($event)" icon="pi pi-ellipsis-h"
                                                                            pTooltip="Options" class="p-button-text simple-btn">
                                                                        </button>
                                                                    </span>
                                                                    <p-overlayPanel #feedbackMenu>
                                                                        <ng-template pTemplate="content">
                                                                            <div class="feedback-options">
                                                                                <p class="option-header">{{items[0].label}}</p>
                                                                                <div *ngFor="let item of items[0].items">
                                                                                    <span class="option-item" (click)="item?.command(); feedbackMenu.hide(); attendees.hide();"><i [class]="item.icon"></i>{{item.label}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </ng-template>
                                                                    </p-overlayPanel>
                                                                </span>
                                                                
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </ng-template>
                                        </p-overlayPanel>
                                    </span>

                                    <span *ngSwitchCase="col.field === 'teamsLink'">
                                        <span class="hyperlink" (click)="openTabLink(user[col.field].joinUrl)">Join Teams</span>
                                    </span>

                                    <span *ngSwitchCase="col.field === 'calendarLink'">
                                        <span class="hyperlink" (click)="openTabLink(user[col.field])">Open Calendar</span>
                                    </span>

                                    <span *ngSwitchCase="col.field === 'calendarStartTime' || col.field === 'calendarEndTime'">
                                        <span>{{user[col.field] | utcToIst : 'hh:mm a' }}</span>
                                    </span>

                                    <span *ngSwitchCase="col.field === 'calendarDate'">
                                        <span>{{user['calendarStartTime'] | date: dateFormat || '-'}}</span>
                                    </span>

                                    <span *ngSwitchCase="col.field === 'calendarScheduledAt'">
                                        <span>{{user[col.field] | date: dateFormat || '-'}}</span>
                                    </span>

                                    <span *ngSwitchCase="col.field === 'organizer'">
                                        <span [pTooltip]="user[col.field].email" tooltipPosition="bottom">{{user[col.field].name}}</span>
                                    </span>

                                    <span *ngSwitchDefault>
                                        {{user[col.field] ? user[col.field] : '-'}}
                                    </span>

                                    <span *ngSwitchCase="col.field === 'mins'">
                                        <span>{{user[col.field]}}</span>
                                    </span>

                                </span>

                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="columns.length">
                                No records found
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <p-sidebar model="true" class="addRole-slider" [style]="{width: '55rem'}"
                    [(visible)]="recordedVideoView" styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right">
                    <div *ngIf="videoLink" style="position:relative; padding: 20px 50px; background-color: #F8F8F8;">
                        <!-- <div style="padding:56.25% 0 0 0;position:relative;"><iframe [src]="videoLink" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="MicrosoftTeams-video (6).mp4"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> -->
                        <div style="padding:56.25% 0 0 0;position:relative;"><iframe [(src)]="videoLink" frameborder="0"
                                allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                                style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                title="MicrosoftTeams-video (6).mp4"></iframe></div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <!-- <app-feedback-form [candidateDetails]="candidateDetails" [headerData]="paramItems"></app-feedback-form> -->
                    </div>
                    <span class="videoLinkUnavailable" *ngIf="!videoLink"> Recorded Video Unavailable</span>

                </p-sidebar>
                <div *ngIf="displayUserDetails">
                    <p-sidebar class="candidateDetaile" [transitionOptions]="'0ms'" [(visible)]="displayUserDetails"
                        styleClass="p-sidebar-lg" position="right" [style]="{width: '921px'}">
                        <app-candidate-info [selectedUser]="selectedUser" [numberOfStages]="0" [disableStage]="true"
                            [disableFeedback]="false" [disableActivity]="false"
                            [statusSettings]="statusSettings"></app-candidate-info>
                    </p-sidebar>
                </div>
                <p-sidebar model="true" class="addRole-slider" [style]="{width: '55rem'}"
                    [(visible)]="submittedCodeView" styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right">
                    <div class="sidebar-header">
                        <div class="sidebar-heading">Submitted Code</div>
                    </div>
                    <p-divider class="p-divider"></p-divider>
                    <div class="language">Language: <span style="color: black;">{{codeLang}}</span></div>
                    <pre class="code-snippets">
                        <code [innerHTML]="userCode"></code>
                    </pre>
                </p-sidebar>
            </div>
        </div>
    </div>
</div>