import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from '../services/global-services.service';
import { LoginService } from '../services/login.service';


@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.css'],
})
export class LoginCallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private globalService: GlobalServicesService,
    private loginService: LoginService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.messageService.add({ severity: 'info', summary: 'Logging in...', detail: 'Logging in via SSO!', sticky: true });
    this.route.fragment.subscribe((fragment: any) => {
      const fragments = fragment.split('&');
      const idToken = fragments[0].split('=')[1];
      this.handleCallback(idToken);
    });
  }
  ngOnDestroy(): void {
    this.messageService.clear();
  }
  handleCallback(idToken: string): any {
    this.loginService.getUserInfo(idToken).subscribe((info) => {
      const { userToken: token, email, name, id, userId, schoolId } = info.data.data;
      const userType = info.data.data.userType;
      let userDetails = { email, name, id, schoolId, userType };
      userDetails.id = info.data.data.userId;
      this.globalService.getUserPrivileges(userType).subscribe((response) => {
        if (response.status === 200) {
          localStorage.setItem('userPrivilege', response.data);
          localStorage.setItem('userDetails', JSON.stringify(userDetails));
          localStorage.setItem('token', token);
          this.router.navigate(['/events']);
        } else {
          console.error({ success: false, message: 'Something Went Wrong!' });
        }
      }, (error) => {
        console.error(error)
      });
    }, (error) => {
      console.error({ success: false, message: 'Failed to get user info', error: error });
    });
  }
}
