import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TalentPoolService } from 'src/app/services/talent-pool.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { nanoid } from 'nanoid';
import { EmailServicesService } from 'src/app/services/email-services.service';


// declare let tinymce: any;
@Component({
  selector: 'app-talentpool-add',
  templateUrl: './talentpool-add.component.html',
  styleUrls: ['./talentpool-add.component.css'],
  providers: [MessageService]
})
export class TalentpoolAddComponent implements OnInit {

  @Input() data: any;
  @Input() eventId: any;
  @Input() eventName: any;

  showEditor: boolean = true;
  templateData: string = 'https://neo-hire.s3.amazonaws.com/bulk-invite/bulkInvite-Template/Sample_Bulk_Invite.xlsx'
  mediaFile: File[] = [];
  mediaBase64: any = '';
  schoolData: any;
  schoolCode: string = '';
  schoolId: string = '';
  uploadingFile: boolean = false;
  sendingSingleInvite: boolean = false;
  sendingBulkInvite: boolean = false;
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;

  inviteUrl: string = '';
  link: string = '';
  inviteForm: FormGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    emailID: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    source: new FormControl('', Validators.required),
    templateId: new FormControl(null, Validators.required),
    subjectTitle: new FormControl('', Validators.required),
    emailContent: new FormControl('', Validators.required),
    eventCategory: new FormControl('', Validators.required),
  })
  bulkInviteForm: FormGroup = new FormGroup({
    Bucket_name: new FormControl(''),
    file_name: new FormControl(''),
    link: new FormControl(''),
    sourceTag: new FormControl('', Validators.required),
    file: new FormControl('', Validators.required),
    templateId: new FormControl(null, Validators.required),
    subjectTitle: new FormControl('', Validators.required),
    emailContent: new FormControl('', Validators.required),
    eventCategory: new FormControl('', Validators.required),
  })

  _selectedCategory: string = 'single-invite';
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;

  templatesList: any;
  clicked: boolean = false;
  // loadingEditor: boolean = false;
  validTemplate: boolean = false;
  errorPlaceholderSingle: any = [];
  errorPlaceholderBulk: any = [];

  // Bulk Declares
  cohortUploaded: boolean = false;
  messageEnable : boolean = false;
  invalidFile : boolean = false;
  countDiv : boolean = false;
  errorFile: any;
  counts: any;
  cohortFileSize: any;
  cohortFile: Array<any> = [];
  cohortFileCopy: Array<any> = [];

  constructor(
    private talentPoolService: TalentPoolService,
    private fileUploadService: FileUploadService,
    private messageService: MessageService,
    private globalService: GlobalServicesService,
    private emailService: EmailServicesService
  ) { }

  ngOnInit(): void {
    this._selectedCategory = 'single-invite';

    // this.loadTinyMce();
    this.validTemplate = false;
    this.schoolData = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '')
      : '';
    this.schoolId = this.schoolData.school_id;
    this.schoolCode = this.schoolData.school_code;
    this.templatesList = [];
    this.emailService.getTemplates('').subscribe((response: any) => {
      let responseData = response?.data ? response.data : [];
      this.templatesList = responseData.filter((each: any)=> each.category === 'Candidates')
    })
  }

  ngOnDestroy(): void {
    this.inviteForm.reset();
    this.bulkInviteForm.reset();
  }
  
  setFormLink(){
    this.inviteUrl = this._selectedCategory == 'single-invite' ? this.inviteForm.controls['eventCategory'].value : this.bulkInviteForm.controls['eventCategory'].value
    this.bulkInviteForm.controls['link'].setValue(this.link);
  
    this.link = `${window.location.protocol}//${window.location.hostname}/${this.inviteUrl}`;
    this.bucketName = this.globalService.bucketName;
  }

  sampleData() {
    let filePath: string = 'manage-candidate/bulk-invite-template/Sample_Bulk_Invite.xlsx'
    let split = this.s3Domain.split('/');
    let fileData: string = `https://${this.globalService.bucketName}.${split[2]}/${filePath}`;
    window.open(fileData);
  }

  handleUpload(event: any, type: string) {
    const file = event.addedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (type === 'bulk-invite') {
        this.fileUpload(file, type)
      }
    };
  }


  onSelectMedia(event: any) {
    this.mediaFile.push(...event.addedFiles);
    this.handleUpload(event, 'bulk-invite')
  }

  onRemoveMedia(event: any) {
    this.mediaFile.splice(this.mediaFile.indexOf(event), 1);
    this.bulkInviteForm.controls['file'].setValue('');
  }

  sendInvite() {
    if (this._selectedCategory === 'single-invite') {
      this.sendingSingleInvite = true;
      // console.log(this.inviteForm.value,'form')
      let payload: any = {
        "candidateData" : [
          {
              "firstName" : this.inviteForm.controls['firstName'].value,
              "lastName" : this.inviteForm.controls['lastName'].value,
              "emailId" : this.inviteForm.controls['emailID'].value,
          }
        ],
        "sourceTag" : this.inviteForm.controls['source'].value,
        "templateId" : this.inviteForm.controls['templateId'].value,
        "eventCategory" : this.inviteForm.controls['eventCategory'].value,
        "link" : this.link,
      }
      if(this.eventId){
        payload.link = this.link + '&eventId='+ this.eventId + '&eventTitle=' +this.eventName;
      }

      this.talentPoolService.singleInvite(payload).subscribe(res => {
        this.inviteForm.reset();
        this.sendingSingleInvite = false;
        if (res.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Invite Sent Successfully' });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable To Send Invite' });
        }
      })
    }
    else if(this._selectedCategory === 'bulk-invite'){
          this.sendingBulkInvite = true;
          if(this.bulkInviteForm.valid){
            if(this.cohortUploaded){
              this.bulkInviteForm.controls['link'].setValue(this.link);
              let payload = this.bulkInviteForm.value;
              payload.actionId = 'bulkInvite';
              delete payload.file;
              if(this.eventId){
                payload.link = this.link + '&eventId='+ this.eventId + '&eventTitle=' +this.eventName;
              }
              this.talentPoolService.bulkInvite(payload).subscribe((res) => {
                this.sendingBulkInvite = false;
              if (res.status === 200) {
                    let result = res.data;
                    if(result.count.errored > 0 && result.count.validated > 0){
                      this.invalidFile = true;
                      this.countDiv = true;
                      this.counts = result?.count;
                      this.errorFile = result.erroredFile;
                      this.messageService.add({ severity: 'warn', summary: 'Partially Correct', detail: 'File is Partially Correct!' });
                      this.messageEnable = true;
                    } 
                    else if(result.count.errored > 0 && result.count.validated == 0){
                      this.invalidFile = true;
                      this.countDiv = true;
                      this.counts = result.count;
                      this.errorFile = result.erroredFile;
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'All the entries are invalid!' });
                      this.messageEnable = true;
                    }
                    else if(result.count.errored == 0 && result.count.validated > 0){
                      this.invalidFile = false;
                      this.countDiv = false;
                      this.bulkInviteForm.reset();
                      this.cohortFile = [];
                      this.cohortFileCopy = [];
                      this.cohortUploaded = false;
                      this.messageEnable = false;
                      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Invite Sent Successfully!' });
                    }
                  }
                else if(res.status == 500 && res.error ){
                  this.invalidFile = true;
                  this.countDiv = false;
                  this.messageEnable = false;
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error});
                }
              })
              console.log(this.invalidFile,'status')
            }
            else {
              this.sendingBulkInvite = false;
              this.invalidFile = false;
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please upload a file to proceed!' });
            }
          }
          else{
            this.sendingBulkInvite = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Valid entries required!' });
          }
      }
  }

  
  cohortUpload(event: any){
    let cohortFile = {
      file : event.addedFiles,
      time : new Date()
    }
    this.cohortFileCopy = event.addedFiles;
    this.cohortFile[0] = cohortFile;
    this.fileUpload(this.cohortFileCopy[0],this.cohortFile[0])
  }

  fileUpload(file: any, type: string) {
    let fileName = file.name.split(".");
    let fileExtension = fileName.pop();
    fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    const pay = {
      Bucket_name: this.bucketName,
      file_name: `manage-candidate/${type}/${this.schoolId}/${nanoid(10)}/${fileName}`,
      type: file.type,
    };

    const blobData: any = file;
    this.uploadingFile = true;
    this.fileUploadService.getSignedUrl(pay).subscribe(
      (url: any) => {
        if (url) {
          const json = url;
          this.fileUploadService.uploadUsingSignedUrl(
            json.data,
            blobData
          ).subscribe(
            (r: any) => {
              if (r && r.status === 200) {
                let file = pay;
                this.bulkInviteForm.controls['Bucket_name'].setValue(file.Bucket_name);
                this.bulkInviteForm.controls['file_name'].setValue(file.file_name);
                this.bulkInviteForm.controls['file'].setValue(file);
                this.cohortUploaded = true;
                this.uploadingFile = false;
              }
            }
          );
        }
      }
    );
  }

  onRemoveExcel(){
    this.cohortUploaded = false;
    this.invalidFile = false;
    this.countDiv = false;
    this.counts = 0;
    this.cohortFile = [];
    this.cohortFileCopy = [];
    this.messageEnable = false;
  }

  errorReportDownload(){
    window.open(this.errorFile,'_blank')
  }

  emailWriteSingle() {
    console.log("this.templatesList ->",this.templatesList)
    let subjectTitle = '';
    let emailTemplate = '';
    this.templatesList.forEach((each: any)=>{
      if(each.id === this.inviteForm.value.templateId)
      {
        subjectTitle = each.subjectTitle;
        emailTemplate = each.templateContent;
      }
    })
    if(this.validateTemplate(emailTemplate, 'single'))
    {
      this.inviteForm.controls['subjectTitle'].setValue(subjectTitle);
      this.inviteForm.controls['emailContent'].setValue(emailTemplate);
    }
    else
    {
      this.inviteForm.controls['subjectTitle'].setValue('');
      this.inviteForm.controls['emailContent'].setValue('');
    }
  }

  emailWriteBulk() {
    let subjectTitle = '';
    let emailTemplate = '';
    this.templatesList.forEach((each: any)=>{
      if(each.id === this.bulkInviteForm.value.templateId)
      {
        subjectTitle = each.subjectTitle;
        emailTemplate = each.templateContent;
      }
    })
    if(this.validateTemplate(emailTemplate))
    {
      this.bulkInviteForm.controls['subjectTitle'].setValue(subjectTitle);
      this.bulkInviteForm.controls['emailContent'].setValue(emailTemplate);
    }
    else
    {
      this.bulkInviteForm.controls['subjectTitle'].setValue('');
      this.bulkInviteForm.controls['emailContent'].setValue('');
    }
  }

  // async loadTinyMce() {
  //   this.loadingEditor = true;
  //   await this.globalService.loadScript(
  //     'script',
  //     'https://cdn.tiny.cloud/1/0eh36k1drqmz94mh92qtt7utdjc0pmvctldubb7sho99iiva/tinymce/5/tinymce.min.js',
  //     'text/javascript',
  //     'examlyck'
  //   );
  //   tinymce.remove();
  //   tinymce
  //     .init({
  //       selector: 'textarea',
  //       content_style:
  //         "@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); body { font-family: Poppins; }",
  //       height: 500,
  //       menu: '',
  //       plugins:
  //         'print preview paste searchreplace autolink ' +
  //         'directionality code visualblocks visualchars fullscreen link media ' +
  //         'codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists ' +
  //         'wordcount textpattern noneditable help charmap quickbars emoticons',
  //       toolbar:
  //         'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ' +
  //         'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | ' +
  //         'forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | ' +
  //         'insertfile quickimage media link anchor codesample | ltr rtl',
  //       toolbar_sticky: true,
  //       images_upload_url: '',
  //       image_caption: true,
  //       quickbars_selection_toolbar:
  //         'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
  //       noneditable_noneditable_class: 'mceNonEditable',
  //       toolbar_mode: 'wrap',
  //       contextmenu: 'link image imagetools table',
  //       relative_urls: false,
  //     })
  //     .then((editor: any) => { this.loadingEditor = false });
  // }

  validateTemplate(emailTemplate: string, inviteType?: string)
  {
    const invitetPlaceholder: any = {"Activation Link" : "${Activation_link}"};
    inviteType === 'single' ? this.errorPlaceholderSingle = [] : this.errorPlaceholderBulk = []; 
    for(const placeholder in invitetPlaceholder)
    {
      if(!emailTemplate.includes(invitetPlaceholder[placeholder]))
      {
        inviteType === 'single' ? this.errorPlaceholderSingle.push(placeholder) : this.errorPlaceholderBulk.push(placeholder);
      }
    }
    return inviteType === 'single' ? (this.errorPlaceholderSingle.length ? false : true) : (this.errorPlaceholderBulk.length ? false : true);
  }

}
