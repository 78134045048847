import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { NgOtpInputModule } from 'ng-otp-input';

// import { ButtonModule } from 'primeng/button';
// import { PanelModule } from 'primeng/panel';
// import { DividerModule } from 'primeng/divider';
// import { SidebarModule } from 'primeng/sidebar';
// import { DropdownModule } from 'primeng/dropdown';
// import { EditorModule } from 'primeng/editor';
// import { InplaceModule } from 'primeng/inplace';
// import { ProgressSpinnerModule } from 'primeng/progressspinner';
// import { DialogModule } from 'primeng/dialog';
// import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChipModule } from 'primeng/chip';
// import { ConfirmPopupModule } from 'primeng/confirmpopup';
// import { ToastModule } from 'primeng/toast';
// import { NgxDropzoneModule } from 'ngx-dropzone';
// import { InputMaskModule } from 'primeng/inputmask';

import { PrimaryAccountComponent } from './primary-account/primary-account.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { BranchesComponent } from './branches/branches.component';
import { DepartmentsComponent } from './departments/departments.component';
import { SharedModule } from '../shared/shared.module';
import { CohortJoiningComponent } from './cohort-joining/cohort-joining.component';
import { DateTimeSettingsComponent } from './date-time-settings/date-time-settings.component';
import { StatusComponent } from './status/status.component';
import { ssocredentialscomponent } from './sso-credentials/sso-credentials.component';



@NgModule({
  declarations: [
    GeneralSettingsComponent,
    PrimaryAccountComponent,
    CompanyDetailsComponent,
    BranchesComponent,
    DepartmentsComponent,
    CohortJoiningComponent,
    DateTimeSettingsComponent,
    StatusComponent,
    ssocredentialscomponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    SharedModule,
    // ButtonModule,
    // PanelModule,
    // DividerModule,
    // SidebarModule,
    // DropdownModule,
    // EditorModule,
    // InplaceModule,
    // ProgressSpinnerModule,
    // DialogModule,
    // OverlayPanelModule,
    ChipModule,
    // ConfirmPopupModule,
    // ToastModule,
    // NgxDropzoneModule,
    // InputMaskModule
  ]
})
export class SettingsModule { }
