<div *ngIf="isLoading" class="loadingOverlay">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>
<div class="pageInfo">
    <div class="settings-header">
        <div class="setting-content_center">
            <em class="pi pi-user"></em>
            <p class="settings-header_name">SSO login credentials</p>
        </div>
        <em class="pi pi-pencil" (click)="openStatusSettings()"></em>
    </div>
</div>
    <div class="info-card">
        <div [style]="{display: 'block', flexWrap: 'wrap'}">
            <div *ngFor="let data of stageTypes; let i = index">
                <div class="info-element">
                    <span class="info-line"></span>
                    <div [style]="{display: 'flex', flexDirection: 'column'} ">
                        <p [style]="{color: 'var(--fc-dark)', width: 'fit-content'}">
                            <strong>{{data.label}} &nbsp;:</strong> {{data.value}}
                        </p>   
                    </div>
                </div>
            </div>
        </div>
    </div>

<div *ngIf="settingsSlider">
    <p-sidebar [(visible)]="settingsSlider" styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right">
        <div class="status-header">
            <div class="status-heading">Edit SSO credentials</div>
            <div class="header-btn">
                <button pButton pRipple type="submit" label="Save" class="p-button-raised p-button-text button-align"
                    (click)="saveStatus()" [loading]="saveBtnLoading"></button>
            </div>
        </div>
        <p-divider class="p-divider"></p-divider>
    
        <div style="margin: 2.25rem 10px 10px 5vw;">
            <span class="p-float-label editStatus-elements">
                <input type="text" id="inputtext" pInputText [(ngModel)]="issuer">
                <label for="dropdown">Issuer <span class="imp">*</span></label>
            </span>
            <span class="p-float-label editStatus-elements">
                <input type="text" id="inputtext" pInputText [(ngModel)]="redirectUri">
                <label for="dropdown">redirectUri <span class="imp">*</span></label>
            </span>
            <span class="p-float-label editStatus-elements">
                <input type="text" id="inputtext" pInputText [(ngModel)]="clientId" >
                <label for="dropdown">clientId <span class="imp">*</span></label>
            </span>
            <span class="p-float-label editStatus-elements">
                <input type="text" id="inputtext" pInputText [(ngModel)]="scope">
                <label for="dropdown">scope <span class="imp">*</span></label>
            </span>            
        </div>
    </p-sidebar>
</div>
<p-toast></p-toast>