import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, Pipe, PipeTransform, Output, EventEmitter } from '@angular/core';
import { MessageService, MenuItem } from 'primeng/api';
import { EventServicesService } from 'src/app/services/event-services.service';
import { JobServicesService } from 'src/app/services/job-services.service';
import { SocketService } from "../../services/socket.service";
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingsService } from 'src/app/services/settings.service';
import { Menu } from 'primeng/menu';
import { MicrosoftAuthserviceService } from 'src/app/services/microsoft-authservice.service';
import { DatePipe } from '@angular/common';
import { firstValueFrom } from 'rxjs';

@Pipe({
  name: 'utcToIst'
})
export class UtcToIstPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const utcDate = new Date(value);
    const istOffset = 330 * 60000; // IST is UTC+5:30
    const istDate = new Date(utcDate.getTime() + istOffset);
    return new DatePipe('en-US').transform(istDate, args);
  }
}

@Component({
  selector: 'app-interview-panel',
  templateUrl: './interview-panel.component.html',
  styleUrls: ['./interview-panel.component.css', '../workflow/workflow.component.css'],
  providers: [MessageService],
})

export class InterviewPanelComponent implements OnInit, OnChanges {
  @Input() selectedInterviewType: string = 'all';
  @Output() needInterviewFilter = new EventEmitter<boolean>();

  jitsiAPI: any;
  interviewStarted: boolean = false;
  userDetails: any;
  schoolId: any;
  eventDetails: any;
  studentId: any;
  sideMenuItems: any = [];
  selectedCandidate: any = {};
  paramItems: any;
  userToEdit: any;

  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showSettingsSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  feedbackSlider: boolean = false;
  editFeedback: boolean = false;
  viewFeedBack: boolean = false;
  createFeedBack: boolean = false;
  displaySaveFilterSettings: boolean = false;
  displaySaveColumnSettings: boolean = false;
  displayUserDetails: boolean = false;
  isInEvent!: boolean;
  showBulkUpload: boolean = false;
  assignSelected: boolean = false;
  usersList: any;
  displayLobbyCandidates: boolean = true;
  displayLiveCandidates: boolean = false;

  columnSearchTerm: string = '';
  filterSearchTerm: string = '';
  downloadSearchTerm: string = '';
  downloadAs: string = 'excel';
  exportName: string = 'userList';
  selectedJobId: string = '';
  selectedEventId: string = '';
  bulkUpload!: MenuItem[];
  items: MenuItem[] = [];
  recruiterList: any = [];
  StatusColour: string = 'green'
  searchEmail: any;
  searchEmailList: string = "";
  formName: string = '';
  userCode: string = '';
  codeLang: string = '';

  // Number Var
  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;
  numberOfStages: number = 0;

  candidateList: any = [];
  ongoingList: any = [];
  stages: any = [];
  usersCol: Array<any> = [];
  _usersCol: Array<any> = [];
  _selectedColumns: Array<any> = [];
  colsToDownload: Array<any> = [];
  pageLimits: Array<any> = [10, 25, 50, 100];
  checkedColumns: Array<any> = [];
  columnsToSave: Array<any> = [];
  savedColumnSettings: Array<any> = [];
  selectedUser: Array<any> = [];
  candidateStatus: any = {};
  excel: File[] = [];
  selectedMenuID: string = 'ongoing';
  userType: string = "";
  selectedUsersList: any = [];
  emails: any = [];
  editorOptions: any = [];
  assigneeList: Array<any> = []
  allUsers: Array<any> = []
  numberOfassigne: string = '';
  recordedVideoView: boolean = false
  submittedCodeView: boolean = false
  videoLink: any = ""
  isLoading: boolean = false;
  mins: any;
  dateFormat: any;
  timeFormat: any;
  statusSettings: any;
  selectedCalendarStatus: string = 'scheduled'
  calendarInvite: string = '';
  
  // interviewTypeOptions: MenuItem[] = [];

  constructor(private globalservice: GlobalServicesService,
    private eventServices: EventServicesService,
    private socketService: SocketService,
    private JobServicesService: JobServicesService,
    private _router: Router,
    private sanitizer: DomSanitizer,
    private globalService: GlobalServicesService,
    public messageService: MessageService,
    private settingsService: SettingsService,
    private ms_authservice: MicrosoftAuthserviceService,
    ) {
    this.userDetails = localStorage.getItem('userDetails')
      ? JSON.parse(localStorage.getItem('userDetails') || '') : '';
    let allPrivilege: any = JSON.parse(this.globalservice.decryptPrivileges());
    this.userType = allPrivilege.userType;

    this.sideMenuItems = [
      { id: 'allCandidates', name: 'All Candidates', isActive: (this.userType == 'admin' || this.userType == 'hiringManager' || this.userType == 'recruiter') ? true : false, view: (this.userType == 'admin' || this.userType == 'hiringManager' || this.userType == 'recruiter') ? true : false },
      { id: 'ongoing', name: 'Ongoing', isActive: (this.userType == 'recruiter') ? true : false, view: true },
      { id: 'assigned', name: 'Assigned', isActive: false, view: true },
      { id: 'unassigned', name: 'Unassigned', isActive: false, view: (this.userType == 'admin' || this.userType == 'hiringManager' || this.userType == 'recruiter') ? true : false },
      { id: 'completed', name: 'Completed', isActive: false, view: true },
      { id: 'calendarInvites', name: "Calendar Invites", isActive: false, view: true }
    ];


    // this.usersCol = [
    //   { index: 1, field: 'name', header: 'Name' },
    //   { index: 2, field: 'email', header: 'E-mail' },
    //   { index: 3, field: 'mobile_number', header: 'Phone' },
    //   { index: 4, field: 'stageName', header: 'Stage Name' },
    //   { index: 5, field: 'status', header: 'Status' },
    //   { index: 6, field: 'interviewDate', header: 'Interview Date' },
    //   { index: 7, field: 'recruiterName', header: 'Interviewer Name' },
    // ]

    // this._selectedColumns = [
    //   { index: 1, field: 'name', header: 'Name' },
    //   { index: 2, field: 'email', header: 'E-mail' },
    //   { index: 3, field: 'mobile_number', header: 'Phone' },
    //   { index: 4, field: 'stageName', header: 'Stage Name' },
    //   { index: 5, field: 'status', header: 'Status' },
    //   { index: 6, field: 'interviewDate', header: 'Interview Date' },
    //   { index: 7, field: 'recruiterName', header: 'Interviewer Name' },
    // ]

    // this.checkedColumns = this._selectedColumns;

  }

  columnSettings: FormGroup = new FormGroup({
    columnName: new FormControl(''),
    columnValue: new FormControl(this.columnsToSave)
  })

  ngOnInit(): void {
    this.getStatusSettings();
    this.dateFormat = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].dateFormat : '';
    // this.globalService.dateFormat.subscribe((res: any) => {
    //   this.dateFormat = res;
    // })
    this.timeFormat = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].timeFormat : '';
    // this.globalService.timeFormat.subscribe((res: any) => {
    //   this.timeFormat = res;
    // })

    this._usersCol = this.usersCol;
    this.colsToDownload = this.usersCol;
    this.checkedColumns = this._selectedColumns;
    this.selectedEventId = localStorage.getItem('selectedEventId') || '';
    this.schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';

    this.items = [{
      label: 'Options',
      items: [
        {
          label: 'View',
          icon: 'pi pi-eye',
          command: () => {
            this.openFeedback(this.userToEdit, 'view');
          }
        }
      ]
    },]
    if (this.userType == 'admin' || this.userType == 'superAdmin') {
      this.items[0].items?.push({
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => {
          this.openFeedback(this.userToEdit, 'edit');
        }
      },
        {
          label: 'Download',
          icon: 'pi pi-download',
          command: () => {
            this.downloadFeedback(this.userToEdit.userId, this.userToEdit.stageId);
          }
        })
    }
    let activeMenu = this.sideMenuItems.find((menu: any) => { if (menu.isActive) return menu });
    this.openSideMenu(activeMenu?.id);
    this.getRecruiterListByEvent()

    if (this.userDetails.id && this.selectedEventId) {
      // Connect to the socket 
      this.socketService.connect(this.selectedEventId, this.userDetails.id);
      this.socketHandler()
    } else {
      alert('Not a valid URL please check')
    }
    this.addColumns();
    this.eventServices.getAllUser().subscribe(res => {
      if(res.data?.items){
        this.allUsers = res.data.items;
      }
    });
  }

  addColumns = () => {
    let testCompletedStage: Array<any> = [];

      this.JobServicesService.getAllStages(this.selectedEventId).subscribe(res => {
        this.stages = res.data;
        
        testCompletedStage = this.stages.filter((stage: any) => stage.type == 'Test');
        testCompletedStage.forEach(stage => {
        // if (stage.type == "Test") {
          this.usersCol.unshift({ index: 1, field: 'testScore', stageId: stage.id, header: stage.name + ' Test Score', testSchedule: stage.scheduleTest });
          this._selectedColumns.unshift({ index: 1, field: 'testScore', stageId: stage.id, header: stage.name + ' Test Score', testSchedule: stage.scheduleTest });
          // }
        });
        
      })
    }

  getRecruiterListByEvent() {
    this.assignSelected = true;
    this.eventServices.getRecruiterListByEvent(this.selectedEventId).subscribe((response) => {
      this.recruiterList.push(response)
      response.data.forEach((user: any) => {
        this.assigneeList.push({ 'label': user.name, 'value': user.id, 'userId': user.id })
      });
    })
  }

  setColumns = (selectedMenuID: string) => {

    
    

    if (selectedMenuID === 'ongoing') {
      this.usersCol = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name' },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'stageName', header: 'Stage Name' },
        { index: 5, field: 'interviewType', header: 'Interview Type' },
        { index: 5, field: 'action', header: 'Join' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'mobile_number', header: 'Phone' },
        { index: 8, field: 'interviewDate', header: 'Interview Date' },
        { index: 9, field: 'startandEnd', header: 'Interview Time' },
        { index: 10, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 11, field: 'isFeedbackSubmitted', header: 'Feedback' }
      ]

      this._selectedColumns = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name' },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'stageName', header: 'Stage Name' },
        { index: 5, field: 'interviewType', header: 'Interview Type' },
        { index: 5, field: 'action', header: 'Join' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'mobile_number', header: 'Phone' },
        { index: 8, field: 'interviewDate', header: 'Interview Date' },
        { index: 9, field: 'startandEnd', header: 'Interview Time' },
        { index: 10, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 11, field: 'isFeedbackSubmitted', header: 'Feedback' }
      ]
      this.checkedColumns = [...this._selectedColumns];
      this._usersCol = [...this.checkedColumns];
      this.colsToDownload = [...this._usersCol];

    } else if (selectedMenuID === 'unassigned') {
      this.usersCol = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name' },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'mobile_number', header: 'Phone' },
        { index: 5, field: 'stageName', header: 'Stage Name' },
        { index: 5, field: 'interviewType', header: 'Interview Type' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'interviewDate', header: 'Interview Date' },
        { index: 8, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 9, field: 'startandEnd', header: 'Interview Time' },

      ]

      this._selectedColumns = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name' },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'mobile_number', header: 'Phone' },
        { index: 5, field: 'stageName', header: 'Stage Name' },
        { index: 5, field: 'interviewType', header: 'Interview Type' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'interviewDate', header: 'Interview Date' },
        { index: 8, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 9, field: 'startandEnd', header: 'Interview Time' },

      ]
      this.checkedColumns = [...this._selectedColumns];
      this._usersCol = [...this.checkedColumns];
      this.colsToDownload = [...this._usersCol];

    } else if (selectedMenuID === 'completed') {
      this.usersCol = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name' },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'mobile_number', header: 'Phone' },
        { index: 5, field: 'stageName', header: 'Stage Name' },
        { index: 5, field: 'interviewType', header: 'Interview Type' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'interviewDate', header: 'Interview Date' },
        { index: 8, field: 'startandEnd', header: 'Interview Time' },
        { index: 9, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 10, field: 'isFeedbackSubmitted', header: 'Feedback' },
        { index: 11, field: 'sourceCode', header: 'Submitted Code' },
        { index: 12, field: 'action', header: 'Recording' },
        { index: 13, field: 'mins', header: 'Duration' }
      ]

      this._selectedColumns = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name' },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'mobile_number', header: 'Phone' },
        { index: 5, field: 'stageName', header: 'Stage Name' },
        { index: 5, field: 'interviewType', header: 'Interview Type' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'interviewDate', header: 'Interview Date' },
        { index: 8, field: 'startandEnd', header: 'Interview Time' },
        { index: 9, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 10, field: 'isFeedbackSubmitted', header: 'Feedback' },
        { index: 11, field: 'submittedCode', header: 'Submitted Code' },
        { index: 12, field: 'action', header: 'Recording' },
        { index: 13, field: 'mins', header: 'Duration' }
      ]
      this.checkedColumns = [...this._selectedColumns];
      this._usersCol = [...this.checkedColumns];
      this.colsToDownload = [...this._usersCol].filter((each)=> each.index <= 9);

    } else if(selectedMenuID === 'calendarInvites'){
      this.usersCol = [
        { index: 1, field: 'attendees', header: 'Attendees' },
        { index: 2, field: 'organizer', header: 'Organizer' },
        { index: 3, field: 'calendarStartTime', header: 'Start Time' },
        { index: 4, field: 'calendarEndTime', header: 'End Time' },
        { index: 5, field: 'calendarDate', header: 'Date' },
        { index: 6, field: 'teamsLink', header: 'Teams' },
        { index: 7, field: 'calendarLink', header: 'Calendar' },
        { index: 8, field: 'calendarScheduledAt', header: 'Scheduled At' },
      ];
      this._selectedColumns = [
        { index: 1, field: 'attendees', header: 'Attendees' },
        { index: 2, field: 'organizer', header: 'Organizer' },
        { index: 3, field: 'calendarStartTime', header: 'Start Time' },
        { index: 4, field: 'calendarEndTime', header: 'End Time' },
        { index: 5, field: 'calendarDate', header: 'Date' },
        { index: 6, field: 'teamsLink', header: 'Teams' },
        { index: 7, field: 'calendarLink', header: 'Calendar' },
        { index: 8, field: 'calendarScheduledAt', header: 'Scheduled At' },
      ];
    }
    else {
      this.usersCol = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name' },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'mobile_number', header: 'Phone' },
        { index: 5, field: 'stageName', header: 'Stage Name' },
        { index: 5, field: 'interviewType', header: 'Interview Type' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'interviewDate', header: 'Interview Date' },
        { index: 8, field: 'startandEnd', header: 'Interview Time' },
        { index: 9, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 10, field: 'isFeedbackSubmitted', header: 'Feedback' },

      ]

      this._selectedColumns = [
        { index: 1, field: 'userId', header: 'Candidate ID' },
        { index: 2, field: 'name', header: 'Name' },
        { index: 3, field: 'email', header: 'E-mail' },
        { index: 4, field: 'mobile_number', header: 'Phone' },
        { index: 5, field: 'stageName', header: 'Stage Name' },
        { index: 5, field: 'interviewType', header: 'Interview Type' },
        { index: 6, field: 'status', header: 'Status' },
        { index: 7, field: 'interviewDate', header: 'Interview Date' },
        { index: 8, field: 'startandEnd', header: 'Interview Time' },
        { index: 9, field: 'recruiterName', header: 'Interviewer Name' },
        { index: 10, field: 'isFeedbackSubmitted', header: 'Feedback' },
      ]
      this.checkedColumns = [...this._selectedColumns];
      this._usersCol = [...this.checkedColumns];
      this.colsToDownload = [...this._usersCol];
    }

  }

  viewRecordedVideo(Link: string) {
    if (Link) {
      this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(Link)
    }
    this.recordedVideoView = true
  }

  tableRefresh()
  {
    this.getDataByLimitPage();
  }

  openSideMenu(selectedMenuID: string, event?: any) {
    this.selectedMenuID = selectedMenuID
    this.checkedColumns = [];
    this.selectedUsersList = [];
    let data = event?.target?.value;
    if(data?.length){
      data = data.trim();
      this.searchEmail = data?.trim().split(/\s+/);
    }
    else if(this.searchEmailList?.length)
    {
      data = this.searchEmailList;
      this.searchEmail = data?.trim().split(/\s+/);
    }
    else
    {
      this.searchEmail = [];
    }
    this.sideMenuItems.map((menu: any) => {
      if (menu.id === selectedMenuID) {
        if(!menu.isActive)
        {
          this.searchEmailList = "";
          this.searchEmail = [];
          console.log("--- !menu.isActive ---", !menu.isActive);
        }
        this.pageSize = 10;
        menu.isActive = true;
        this.getUsersByLimit();
      } else {
        menu.isActive = false;
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.jitsiAPI.addListener('videoConferenceLeft', () => {
    //   this.interviewStarted = false;
    // })

    if(changes['selectedInterviewType']){
      this.getDataByLimitPage();
      console.log("onChanges")
    }
  }

  splitLobbyCandidate(status: any) {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.first = 1;
      this.last = 0;
      if(status === 'lobby'){
        this.displayLiveCandidates = false; 
        this.displayLobbyCandidates = true;
        this.getLobbyUsers();
      }
      else if(status === 'live'){
        this.displayLiveCandidates = true; 
        this.displayLobbyCandidates = false; 
        this.getLobbyUsers();
      }
  }

  searchEmailCandidate = (event: any) => {
    let data = event.target.value;
    this.searchEmail = data.trim().split(/\s+/);

  }

  candidateAssing = (assign: any, stagesOp: any) => {
    this.JobServicesService.Assignedrecruiter(this.selectedUsersList.map((user: any) => { return user.id }), assign.value, this.selectedUsersList.map((user: any) => { return user.userId }), this.selectedEventId).subscribe(res => {
      if (res) {
        this.openSideMenu(this.selectedMenuID)
      }
    })
    this.selectedUsersList = [];
    stagesOp.hide(assign);
  }

  getUsersByLimit = () => {    
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = 0;
    this.getDataByLimitPage();
  };

  setlast()
  {
    if(this.last === 0)
    {
      this.last = this.pageSize <= this.totalLength ? this.pageSize : this.totalLength;
    }
  }
  

  next(): void {
    this.first = this.first + this.pageSize;
    this.last = this.last + this.pageSize < this.totalLength ? this.pageSize + this.last : this.totalLength;
    this.pageNumber += 1;
    this.getDataByLimitPage();
  }

  prev(): void {
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize >= this.totalLength ? this.totalLength - this.last : this.pageSize;
    this.pageNumber -= 1;
    this.getDataByLimitPage();
  }

  isLastPage(): boolean {
    return this.totalLength ? this.first > this.totalLength - this.pageSize : true; 
  }

  isFirstPage(): boolean {
    return this.first > 1 ? false : true;
  }

  applySavedSettings = (savedColumnValue: Array<any>) => {
    this.checkedColumns = this.usersCol.filter(e => savedColumnValue.includes(e.field));
  }

  deleteSavedColumns = (columnId: string) => {
    this.loadingSpinner = true;
    // this.jobService.deleteColumnCandidates(this.selectedJobId, columnId).subscribe(res => {
    //   this.savedColumnSettings = res.data?.selectedColumns;
    //   this.loadingSpinner = false;
    // })
  }

  searchColumns = (searchTerm: string) => {
    if (this.showSettingsSidebar) {
      this._usersCol = this.usersCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (this.showDownloadSidebar) {
      this.colsToDownload = this.usersCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }

  showSaveColumnSettings = () => {
    this.displaySaveColumnSettings = true;
  }

  clearSelectedColumns = () => {
    this.checkedColumns = [
      { index: 1, field: 'name', header: 'Name' },
      { index: 2, field: 'email', header: 'E-mail' }
    ];
  }

  applySelectedColumns = () => {
    this._selectedColumns = this.sortedColumns();
    this.showSettingsSidebar = false;
  }

  private sortedColumns(): any[] {
    return this.checkedColumns.sort((a, b) => (a.index < b.index) ? -1 : 1);
  }

  saveColumnSettings = () => {
    this.loadingSpinner = true;
    this.columnsToSave = this.checkedColumns.map(e => e.field);

    this.columnSettings.setValue({
      columnName: this.columnSettings.get("columnName")?.value,
      columnValue: this.columnsToSave
    });

    // if (this.isInEvent) {
    //   this.eventService.saveColumnCandidate(this.selectedJobId, [this.columnSettings.value], this.selectedEventId).subscribe(result => {
    //     this.savedColumnSettings = result.data.selectedColumns;
    //   })
    // } else
    //   this.jobService.saveColumnCandidate(this.selectedJobId, [this.columnSettings.value]).subscribe(result => {
    //     this.savedColumnSettings = result.data.selectedColumns;
    //   })

    this.loadingSpinner = false;
    this.displaySaveColumnSettings = false;

  }


  handleSelection = (event: any) => {
    this.emails = event.map((user: any) => (user.email));
  }

  exportCandidate = (type: string) => {
    let checkedColumnFields: any = ['name'];
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    this.candidateList.forEach((eachData: any) => {
      let filterData: any = {};
      checkedColumnFields.forEach((item: any) => {
        eachData.hasOwnProperty(item) && (filterData[item] = eachData[item]);
      });
      if (
        checkedColumnFields.find((element: string) => element === 'location')
      ) {
        let locationString: string = '';
        eachData.locationInformation.forEach((eachlocation: any) => {
          locationString +=
            `${locationString.length ? ', ' : ''}` + eachlocation.location;
        });
        filterData['location'] = locationString;
      }
      afterFilter.push(filterData);
    });
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: type === 'excel' ? 'xlsx' : 'csv', type: 'array' });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE =
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  getEventDetails() {
    this.eventServices.getEventById(this.selectedEventId).subscribe(response => {
      this.eventDetails = response?.data;
      // this.candidateList = [...this.eventDetails]
    })
  }

  openJitsiContainer(data: any) {
    const userID = data.userId
    const eventId = this.selectedEventId
    this.eventServices.checkIfUserInLobby(eventId, userID).subscribe(async (response) => {
      if (response.status) {
        const candidateEmail = data.email;
        const url = this._router.serializeUrl(this._router.createUrlTree(['/jitsiContainer'], {
          queryParams: {
            userID: userID, eventId: eventId, c_userName: this.userDetails.name,
            candidateName: data.name, schoolId: this.schoolId, candidateEmail: candidateEmail,
            stageId: data.stageId, roomName: data.roomName
          }
        }));
        window.open(url, '_blank');
        
        this.JobServicesService.Assignedrecruiter([data?.id], this.userDetails.id, [data?.userId], this.selectedEventId).subscribe(async (response) => {
          return true
        })
        // this.eventServices.getJobUserMapping(eventId, userID).subscribe(async (response) => {
         
        //   this.JobServicesService.Assignedrecruiter([response?.data?.id], this.userDetails.id, [response?.data?.userId], this.selectedEventId).subscribe(async (response) => {
        //     console.log("response", response)
        //   })
        // })
      } else {
        this.messageService.add({ severity: 'error', summary: 'Unable to connect', detail: "Candidate not available in the lobby" });
        await this.updateInterviewStatus(data, 'Invited');
        await this.openSideMenu(this.selectedMenuID);
      }
    })
  }

  public updateInterviewStatus(data: any, status: string) {
    return new Promise((resolve) => {
      let payload = {
        id: data.id,
        status: status,
        userId: data.userId,
        eventId: data.eventId,
        schoolId: data.schoolId,
        stageId: data.stageId
      };
      this.eventServices.updateInterviewStatus(payload).subscribe((response) => {
        if (!response) {
          this.messageService.add({ severity: 'error', summary: 'Unable to update', detail: "Updation fail, Please contact Adminstrator" });
          resolve(false);
        } else {
          resolve(true);
        }
      });
    })
  };

  private socketHandler() {
    const sub = this.socketService.messageRecieved.subscribe((message: any) => {
      if (message && message.action) {
        // switch (message.action) {
        //   case "meetingEnd": {
        //     if(this.selectedMenuID == "ongoing"){
        //       this.getLobbyUsers();
        //       this.setColumns('ongoing')
        //       this.ongoingList = [];
        //       }
        //     break;
        //   };
        //   case "candidateStatusUpdate": {
        //     break;
        //   }
        // }
        if (message.action == "candidateStatusUpdate") {
          if (this.selectedMenuID == "ongoing") {
            this.getLobbyUsers();
          }
        } else {
          if (this.selectedMenuID == "ongoing") {
            this.getLobbyUsers();
            this.setColumns('ongoing')
            this.ongoingList = [];
          }
        }
      }
    })
  }

  openFeedback = (user: any, action?: string) => {
    console.log("🚀🔥 ~ file: interview-panel.component.ts:781 ~ InterviewPanelComponent ~ user:", user)
    this.loadingSpinner = true;
    this.eventServices.getParticularStage(this.selectedEventId, user.stageId).subscribe(res => {
      if (res.data?.scheduleInterview) this.formName = res.data.scheduleInterview.interviewType;
      this.loadingSpinner = false;
      if (action == 'edit') this.editFeedback = true;
      else if (action == 'view') this.viewFeedBack = true;
      else if (action == 'create') this.createFeedBack = true;
      this.paramItems = {
        eventId: this.selectedEventId,
        stageId: user.stageId,
        userID: user.userId,
        candidateEmail: user.email
      }
      if(user?.interviewType == 'offline'){
        this.paramItems.interviewType = user.interviewType;
      }
      this.feedbackSlider = true;
    })
  }

  downloadFeedback = (userId: string, stageId: number) => {
    this.loadingSpinner = true;
    this.eventServices.getFeedbackDownloadLink(this.selectedEventId, userId, stageId).subscribe((res:any) => {
      this.loadingSpinner = false;
      let downloadLink = res?.link;
      window.open(downloadLink);
    })
  }

  openViewCode = (user: any) => {
    this.userCode = user.submittedCode;
    this.codeLang = user.submittedLanguage;
    this.editorOptions = { theme: 'vs-dark', language: user.submittedLanguage };
    this.submittedCodeView = true;
  }

  showCandidateDetails(userDetail: any, col?: any) {
    if (col?.field === 'name') {
      this.selectedUser = [];
      this.isLoading = true;
      let email = userDetail.email;
      this.eventServices.getUserByEmail(email, this.selectedEventId).subscribe((res: any)=>{
        this.isLoading = false;
        this.displayUserDetails = true;
        this.selectedUser = res?.data;
      })
    }
  }

  getDataByLimitPage()
  {
    switch (this.selectedMenuID) {
      case "allCandidates": {
        this.needInterviewFilter.emit(true)
        this.setColumns(this.selectedMenuID)
        this.getAllCandidatesByJob();
        break;
      }
      case "ongoing": {
        this.needInterviewFilter.emit(true)
        this.setColumns(this.selectedMenuID)
        this.getLobbyUsers();
        break;
      }
      case "assigned": {
        this.needInterviewFilter.emit(true)
        this.setColumns(this.selectedMenuID)
        this.getAssignedCandidatesById();
        break;
      }
      case "unassigned": {
        this.needInterviewFilter.emit(true)
        this.setColumns(this.selectedMenuID)
        this.getUnAssignedCandidatesById();
        break;
      }
      case "completed": {
        this.needInterviewFilter.emit(true)
        this.setColumns(this.selectedMenuID)
        this.getCompletedCandidate();
        break;
      }
      case "calendarInvites": {
        this.needInterviewFilter.emit(false)
        this.setColumns(this.selectedMenuID)
        this.getCalendarInvites(this.selectedCalendarStatus);
      }
    }
  }

  //All Candidates List
  getAllCandidatesByJob() {
    this.candidateList = [];
    this.totalLength = 0;
    this.loadingSpinner = true;
    
    let payload = {
      "searchEmail" : this.searchEmail,
      "recruiterId" : null
    }
    this.JobServicesService.getInterviewCandidatesByStatus(payload, this.selectedEventId, 'all', this.pageNumber, this.pageSize, this.selectedInterviewType).subscribe((response: any) => {
      let tempData: any = []
      response.data.totalItems.map((ele: any) => {
        tempData.push(ele)
      })
      let recruiter_HM: any = {}
      this.assigneeList.forEach((list: any) => {
        recruiter_HM[`${list.userId}`] = list.label
      })

      tempData.forEach((data: any) => {
        if (data.interviewDate) {
          let dateFormat = this.dateFormat.toUpperCase();
          data.interviewDate = moment.utc(data.interviewDate).format(dateFormat);
        }
        if (data.startTime && data.endTime) {
          console.log(this.timeFormat,'time')
          data.startandEnd = `${moment.utc(data.startTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')} - ${moment.utc(data.endTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')}`;
        } else {
          data.startandEnd = '-'
        }
        data.recruiterName = recruiter_HM[`${data.recruiterId}`] || '-';
        if (data.status == null) '-';
        if (data.isFeedbackSubmitted) data.isFeedbackSubmitted = 'Submitted';

      })
      // if(this.selectedInterviewType == 'offline'){
      //   this.candidateList = tempData.filter((data: any) => data.interviewType == 'offline');
      // } else if(this.selectedInterviewType == 'online'){
      //   this.candidateList = tempData.filter((data: any) => data.interviewType == undefined);
      // } else if (this.selectedInterviewType == 'all'){
      //   this.candidateList = tempData;
      // }
      this.candidateList = tempData;
      this.totalLength = response.data.totalCount;
      this.setlast();
      this.loadingSpinner = false;
    });
  }

  //ongoing List
  getLobbyUsers = () => {
    this.candidateList = [];
    this.totalLength = 0;
    this.loadingSpinner = true;
    let payload: any = {
      "searchEmail" : this.searchEmail
    }
    let lobbyStatus: string = "lobby";
    this.displayLobbyCandidates ? lobbyStatus = "lobby" : null;
    this.displayLiveCandidates ? lobbyStatus = "onGoing" : null;
    if(lobbyStatus === "lobby")
    {
      payload.recruiterId = null;
    }
    else if(lobbyStatus === "onGoing")
    {
      lobbyStatus = "inmeeting"
      payload.recruiterId =  (this.userType !== 'admin' && this.userType !== 'hiringManager') ? this.userDetails.id : null
    }
    this.JobServicesService.getInterviewCandidatesByStatus(payload, this.selectedEventId, lobbyStatus, this.pageNumber, this.pageSize, this.selectedInterviewType).subscribe((response: any) => {
      let tempData: any = []
      response?.data?.totalItems.map((ele: any) => {
        tempData.push(ele)
      })
      let recruiter_HM: any = {}
      this.assigneeList.forEach((list: any) => {
        recruiter_HM[`${list.userId}`] = list.label
      })
        tempData.forEach((data: any) => {
          if (data.interviewDate) {
            let dateFormat = this.dateFormat.toUpperCase();
            data.interviewDate = moment.utc(data.interviewDate).format(dateFormat);
          }
          if (data.startTime && data.endTime) {
            console.log(this.timeFormat,'time')
            data.startandEnd = `${moment.utc(data.startTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')} - ${moment.utc(data.endTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')}`;
          } else {
            data.startandEnd = '-'
          }
        data.recruiterName =recruiter_HM[`${data.recruiterId}`] ?? '-'
        if (data.status == null) '-';
        if (data?.isFeedbackSubmitted) 
        {
          data.isFeedbackSubmitted = 'Submitted';
        }
      })
      this.candidateList = tempData;
      this.totalLength = response.data.totalCount || 0;
      this.setlast();
      this.loadingSpinner = false;
    })
  }

  //assigned List
  getAssignedCandidatesById() {
    this.candidateList = [];
    this.totalLength = 0;
    this.loadingSpinner = true;
    
    let payload = {
      "searchEmail" : this.searchEmail,
      "recruiterId" : (this.userType !== 'admin' && this.userType !== 'hiringManager') ? this.userDetails.id : null
    }
    this.JobServicesService.getInterviewCandidatesByStatus(payload, this.selectedEventId, 'assigned', this.pageNumber, this.pageSize, this.selectedInterviewType).subscribe((response: any) => {
      let tempData: any = []
      response?.data?.totalItems.map((ele: any) => {
        tempData.push(ele)
      })
      let recruiter_HM: any = {}
      this.assigneeList.forEach((list: any) => {
        recruiter_HM[`${list.userId}`] = list.label
      })

      tempData.forEach((data: any) => {
        if (data.interviewDate) {
          let dateFormat = this.dateFormat.toUpperCase();
          data.interviewDate = moment.utc(data.interviewDate).format(dateFormat);
        }
        if (data.startTime && data.endTime) {
          console.log(this.timeFormat,'time')
          data.startandEnd = `${moment.utc(data.startTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')} - ${moment.utc(data.endTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')}`;
        } else {
          data.startandEnd = '-'
        }
        data.recruiterName = recruiter_HM[`${data.recruiterId}`] || '-'
        if (data.status == null) '-';
        if (data.isFeedbackSubmitted) data.isFeedbackSubmitted = 'Submitted';
      })
      this.candidateList = tempData;
      this.totalLength = response.data.totalCount || 0;
      this.setlast();
      this.loadingSpinner = false;
    });
  }

  // unassigned List
  getUnAssignedCandidatesById() {
    this.candidateList = [];
    this.totalLength = 0;
    this.loadingSpinner = true;
    let payload = {
      "searchEmail" : this.searchEmail,
    }
    this.JobServicesService.getInterviewCandidatesByStatus(payload, this.selectedEventId, 'unAssigned', this.pageNumber, this.pageSize, this.selectedInterviewType).subscribe((response: any) => {
      let tempData: any = []
      response?.data.totalItems.map((ele: any) => {
        tempData.push(ele)
      })
      tempData.map((data: any, index: any) => {
        if (data.interviewDate) {
          let dateFormat = this.dateFormat.toUpperCase();
          data.interviewDate = moment.utc(data.interviewDate).format(dateFormat);
        }
        if (data.startTime && data.endTime) {
          console.log(this.timeFormat,'time')
          data.startandEnd = `${moment.utc(data.startTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')} - ${moment.utc(data.endTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')}`;
        } else {
          data.startandEnd = '-'
        }
        if (data.recruiterId) {
          tempData.splice(index, 1)
        }
      })
      this.candidateList = tempData;
      this.totalLength = response?.data.totalCount || 0;
      this.setlast();
      this.loadingSpinner = false;
    });
  }

  // completed List
  getCompletedCandidate = () => {
    let start, end, total_seconds, h, m, s;
    this.candidateList = [];
    this.totalLength = 0;
    this.loadingSpinner = true;
    let payload = {
      "searchEmail" : this.searchEmail,
      "recruiterId" : (this.userType !== 'admin' && this.userType !== 'hiringManager') ? this.userType == 'recruiter' ? null : this.userDetails.id : null
    }
    this.JobServicesService.getInterviewCandidatesByStatus(payload, this.selectedEventId, 'meetingend', this.pageNumber, this.pageSize, this.selectedInterviewType).subscribe((response: any) => {
      let tempData: any = []
      response?.data?.totalItems.map((ele: any) => {
        tempData.push(ele)
      })
      let recruiter_HM: any = {}      
      this.assigneeList.forEach((list: any) => {
        recruiter_HM[`${list.userId}`] = list.label
      })

      tempData.forEach((data: any) => {
        if (data.interviewDate) {
          let dateFormat = this.dateFormat.toUpperCase();
          data.interviewDate = moment.utc(data.interviewDate).format(dateFormat);
        }
        if (data.startTime && data.endTime) {
          data.startandEnd = `${moment.utc(data.startTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')} - ${moment.utc(data.endTime, "HH:mm:ss").local().format(this.timeFormat == 'hh:mm' ? 'hh:mm A' : 'HH:mm')}`;
        } else {
          data.startandEnd = '-'
        }        
        data.recruiterName = recruiter_HM[`${data.recruiterId}`] || '-'
        if (data.status == null) '-';
        if (data.isFeedbackSubmitted == true) data.isFeedbackSubmitted = 'Submitted';
        if (data.duration) {
          if (data.duration.startDT && data.duration.endDT) {
            // Create moment objects for start and end times
            let startMoment = moment(data.duration.startDT, "DD-MM-YYYY hh:mm:ss");
            let endMoment = moment(data.duration.endDT, "DD-MM-YYYY hh:mm:ss");

            // Check if the end time is earlier than the start time
            if (endMoment.isBefore(startMoment)) {
              // If the end time is earlier in the same AM/PM period, adjust by adding 12 hours
              if (endMoment.hours() < 12) {
                endMoment.add(12, 'hours');
              }
            }

            // Calculate the duration between start and end times in milliseconds
            const duration = moment.duration(endMoment.diff(startMoment));

            // Extract hours, minutes, and seconds from the duration
            const hours = Math.floor(duration.asHours());
            const minutes = duration.minutes();
            const seconds = duration.seconds();
            // Format the duration into a readable string
            data.mins = `${hours} h ${minutes} m ${seconds} s`;
          } else {
            data.mins = '-';
          }
      } else {
        data.mins = '-';
      }
      })
      this.candidateList = tempData;
      this.totalLength = response?.data?.totalCount || 0;
      this.setlast();
      this.loadingSpinner = false;
    })
  }

  getCalendarInvites = (meetingStatus: string) => {
    this.selectedCalendarStatus = meetingStatus;
    this.candidateList = [];
    this.loadingSpinner = true;
    
    this.ms_authservice.getCalendarInvites(this.selectedEventId, meetingStatus).subscribe(res => {
      if(res.status == 200 || res?.data?.items){
        this.candidateList = res.data.items;
        this.totalLength = res.data.totalItems;
        this.loadingSpinner = false
      } else {
        this.loadingSpinner = false;
        this.messageService.add({ severity: 'error', summary: 'Someting went wrong!', detail: "Unable to fetch data" })
      }
    })
  }

  openTabLink(url:string){
    window.open(url, '_blank'); 
  }

  getStatusSettings = () => {
    this.loadingSpinner = true;
    this.settingsService.getStageStatusData().subscribe(res => {
      if(res.status == 200){
        this.statusSettings = res?.data ? res.data.stageStatus : {};
        this.statusSettings['Defalut'] =  {
          "rejected": "Rejected",
          "pending": "Pending",
          "shortlisted": "Shortlisted",
          "waitlisted": "Waitlisted"
      }
        this.loadingSpinner = false;
      }
    })
  }

  openOfflineInterview = async(user: any) => {
    const userID = user.userId
    const eventId = this.selectedEventId
    const candidateEmail = user.email;
    const url = this._router.serializeUrl(this._router.createUrlTree(['/feedback-submission'], {
      queryParams: {
        userID: userID, eventId: eventId, c_userName: this.userDetails.name, lobbyId: user.id,
        candidateName: user.name, schoolId: this.schoolId, candidateEmail: candidateEmail,
        stageId: user.stageId, venue: user.venue
      }
    }));
    window.open(url, '_blank');
    
    if(user.status != 'InMeeting'){
      await this.updateInterviewStatus(user, 'InMeeting');
      let payload: any = {
        userId: user.userId,
        record: 'start',
        eventId: user.eventId,
        stageId: user.stageId
      };
      const response = await firstValueFrom(this.eventServices.saveRecordingDuration(payload));
      console.log("Recording Started : ", response);
    }
    this.JobServicesService.Assignedrecruiter([user?.id], this.userDetails.id, [user?.userId], this.selectedEventId).subscribe(async (response) => {
      return true
    })
  }

  openFeedbackForm = (candidate:any, inviteId:string) => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails') || '');
    const eventId = this.selectedEventId

    const url = this._router.serializeUrl(this._router.createUrlTree(['/feedback-submission'], {
      queryParams: {
        calendarInvite: inviteId , userID: candidate.candidateId, eventId: eventId, c_userName: userDetails.name,
        candidateName: candidate.name, schoolId: this.schoolId, candidateEmail: candidate.email,
        stageId: candidate.stageId
      }
    }));
    window.open(url);
  }

  getUserName = (id:any) => {
    return this.allUsers.filter((each:any) => each.id == id)[0].name;
  }

  setUserToEdit(user:any, inviteId: string){
    this.calendarInvite = inviteId;
    user.userId = user.candidateId;
    this.userToEdit = user;
  }
}