import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-sso-credentials',
  templateUrl: './sso-credentials.component.html',
  styleUrls: ['./sso-credentials.component.css'],
})
export class ssocredentialscomponent implements OnInit {

  isLoading: boolean = false;
  saveBtnLoading: boolean = false;
  settingsSlider: boolean = false;
  stageTypes: any = [];
  userDetails: any = {};
  selectedStatusLabel: string = '';

  issuer: string = '';
  redirectUri: string = '';
  clientId: string = '';
  scope: string[] = [];
  schoolDetails: any;
  credentialsExist: boolean | undefined;

  constructor(private messageService: MessageService, private settingService: SettingsService) { }

  ngOnInit(): void {
    this.userDetails = localStorage.getItem('userDetails')
      ? JSON.parse(localStorage.getItem('userDetails') || '')
      : '';
    this.schoolDetails = localStorage.getItem('schoolDetails')
      ? JSON.parse(localStorage.getItem('schoolDetails') || '')
      : '';
    this.getCredentials();
  }

  openStatusSettings() {
    this.settingsSlider = true;
  }

  getCredentials() {
    if (this.userDetails.email && this.schoolDetails.length) {
      this.settingService.getSSOCredentials(this.schoolDetails[0].schoolId).subscribe(
        response => {
          if (response?.data) {
            const credentials = response.data[0];
            this.issuer = credentials.issuer || '';
            this.redirectUri = credentials.redirectUri || '';
            this.clientId = credentials.clientId || '';
            this.scope = credentials.scope || [];
            if (this.issuer && this.clientId) {
              this.credentialsExist = true;
              this.stageTypes = [
                { label: 'Issuer', value: this.issuer },
                { label: 'Redirect URI', value: this.redirectUri },
                { label: 'Client ID', value: this.clientId },
                { label: 'Scope', value: this.scope.join(', ') }
              ];
            }
          } else {
            this.credentialsExist = false;
          }
          this.isLoading = false;
        },
        error => {
          console.error('Error fetching SSO credentials', error);
          this.isLoading = false;
        }
      );
    } else {
      console.error('User details or school details are missing');
      this.isLoading = false;
    }
  }

  saveStatus() {
    const ssoCredentials = {
      issuer: this.issuer,
      redirectUri: this.redirectUri,
      clientId: this.clientId,
      scope: this.scope
    };

    if (this.credentialsExist) {
      this.settingService.updateSSOCredentials(this.userDetails.id, ssoCredentials).subscribe(
        response => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'SSO credentials updated successfully!' });
          this.settingsSlider = false;
        },
        error => {
          console.error('Error updating SSO credentials', error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update SSO credentials.' });
        }
      );
    } else {
      this.settingService.saveSSOCredentials(ssoCredentials).subscribe(
        response => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'SSO credentials saved successfully!' });
          this.settingsSlider = false;
        },
        error => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to save SSO credentials.' });
        }
      );
    }
  }
}
