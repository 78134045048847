import { Component, OnInit, Input, Output, OnChanges, EventEmitter, OnDestroy, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EventServicesService } from 'src/app/services/event-services.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { FormBuilderService } from 'src/app/services/form-builder.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import {nanoid} from 'nanoid'
import { MicrosoftAuthserviceService } from 'src/app/services/microsoft-authservice.service';

export interface FormModel {
  fieldId?: string,
  fieldOrder: number,
  fieldName: string,
  fieldDescription: string,
  isMandatory: boolean,
  formName: string,
  displaySection: string,
  placeHolder?: string,
  fieldType: string,
  answer?: Array<any>,
  isValid?: boolean,
  schoolId?: string,
  dependant?: Dependancy,
  fieldDefaultValue?: Array<any>,
  fieldOptions?: Array<FieldOptions>,
  fileProperities?: FieldProperties,
  visible?: boolean,
  // interrogatorEmail?: string,
  // interrogatorId?: string,
  // interrogatorName?: string,
  // interrogatorType?: string
}

export interface Dependancy {
  dependantOn: string,
  dependantValues: Array<string>
}

export interface FieldOptions {
  label: string,
  value: any
}

export interface FieldProperties {
  fileFormat: Array<string>,
  fileSize: any
}

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.css']
})
export class FeedbackFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() calendarInvite: string = '';
  @Input() formName: string = '';
  @Input() headerData: any;
  @Input() inEditMode: boolean = false;
  @Input() inViewMode: boolean = false;
  @Input() createMode: boolean = false;
  @Input() onlyFeedbackView: boolean = false;
  @Input() isOfflineInterview: boolean = false;
  @Input() needEndInterview: boolean = false;
  @Output() isLoadingFeedback = new EventEmitter<boolean>();

  templateModels: Array<FormModel> = [];
  templateCollections: Array<Array<FormModel>> = [];
  feedbackCollections: Array<any> = [];
  feedbackForm: Array<FormModel> = [];
  invalidField: any;
  templateData: any;
  candidateDetails: any;
  scoreSections: Array<string> = ['Learnability', 'Project', 'Java Programming Concepts', 'Python Programming Concepts', 'Database Concepts', 'Data Structures and Algorithms Concepts'];

  pageNumber: number = 1;
  signedProfileUrl: string = '';

  clicked: boolean = false;
  isFormValid: boolean = false;
  submitBtnLoading = false;
  submitted: boolean = false;
  isLoading: boolean = false;
  displayFeedback: boolean = true;
  displayResume: boolean = false;
  endInterveiwModel: boolean = false;
  endBtnLoading: boolean = false;
  ansChange: any;
  pdfSrc: string = '';
  userDetails: any;
  interrogatorDetails: any;
  file: any = [];
  uploadingFile: boolean = false;
  assetBucketName: string = this.globalService.assetBucketName;
  referenceDoc: any = '';
  savedReferenceDoc: string = '';
  userType: string = "";
  s3Domain: string = 'https://s3.amazonaws.com'; 
  avatar:string = `https://${this.globalService.bucketName}.s3.amazonaws.com/default-avatar.webp`
  feedbackSubmittedModel: boolean = false;

  // formName: string = '';

  constructor(
    private formBuilderServices: FormBuilderService,
    private messageServices: MessageService,
    private activatedRoute: ActivatedRoute,
    private fileUploadService: FileUploadService,
    private eventServices: EventServicesService,
    private globalService: GlobalServicesService,
    public messageService: MessageService,
    public ms_authService: MicrosoftAuthserviceService,
  ) { 
    let allPrivilege: any = JSON.parse(this.globalService.decryptPrivileges());
    this.userType = allPrivilege.userType;
  }

  ngOnInit(): void {
    this.getTemplates();
    this.userDetails = localStorage.getItem('userDetails')
      ? JSON.parse(localStorage.getItem('userDetails') || '')
      : '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.headerData?.candidateEmail) {
      this.getCandidateDetails();
    }

    if (changes['formName']?.currentValue !== changes['formName']?.previousValue || changes['createMode']?.currentValue != changes['createMode']?.previousValue) {
      this.getTemplates();
    } else {
      this.getFeedbackForm();
    }

    if(!this.createMode && !this.inViewMode && !this.inEditMode) this.clicked = false;
  }

  getTemplates = () => {
    this.isLoading = true;
    let _templateModels: Array<any> = [];
    this.templateModels = [];
    this.formBuilderServices.getTemplate().subscribe(res => {
      res.data.formDetails.forEach((data: any) => {
        if (data.formDetails[0].formName == this.formName) {
          _templateModels = data.formDetails;
        }
      });

      this.templateModels = _templateModels.map((model: FormModel) => ({ ...model, answer: [], isValid: !model.isMandatory, visible: model.dependant ? false : true }));
      
      let sections: Array<string> = [];
      let _displaySection = '';

      _templateModels.forEach((model: FormModel) => {
        if (_displaySection !== model.displaySection && !sections.includes(model.displaySection)) {
          _displaySection = model.displaySection;
          sections.push(_displaySection);
        }
      })
      this.templateCollections = [];
      sections.forEach((eachSection: string) => {
        this.templateCollections.push(this.templateModels.filter((model: FormModel) => model.displaySection == eachSection));
      })

      if (this.inEditMode || this.inViewMode) this.getFeedbackForm();
      else this.isLoading = false;
    })
  }

  getFeedbackForm = () => {
    this.isLoading = true;
    this.feedbackForm = [];
    this.interrogatorDetails = [];
    this.isLoading = true;
    this.formBuilderServices.getFeedbackform(this.headerData.eventId, this.headerData.stageId, this.headerData.userID).subscribe(res => {
      this.feedbackForm = res.data ? res.data[0]?.formDetails : [];
      this.savedReferenceDoc = res?.data[0]?.referenceDocument ? res?.data[0]?.referenceDocument : '';
      this.templateModels.forEach((each:any, index: any) => {
        if(each.fieldName == 'Section Status'){
          if(each.fieldName != this.feedbackForm[index].fieldName){
            this.feedbackForm.splice(index, 0, each);
          }
        }
      })

      let sections: Array<string> = [];
      let _displaySection = '';

      this.feedbackForm.forEach((model: FormModel) => {
        if (_displaySection !== model.displaySection && !sections.includes(model.displaySection)) {
          _displaySection = model.displaySection;
          sections.push(_displaySection);
        }
      })
      this.feedbackCollections = [];
      sections.forEach((eachSection: string) => {
        this.feedbackCollections.push({field: this.feedbackForm.filter((model: FormModel) => model.displaySection == eachSection)});
      })

      this.feedbackCollections.forEach((each:any) => {
        if(this.scoreSections.includes(each.field[0].displaySection)){
            let score = 0;
            let totalScore = 0;
            each.field.forEach((field:any) => {
							if(field.fieldType == 'radio'){
								score += field.answer.length && Number(field.answer[0]);
								totalScore += 5;
							}
						});
            each.score = score;
            each.totalScore = totalScore;
        }
    })

      this.interrogatorDetails = res.data ? res.data[0] : null;
      if (this.inEditMode || this.inViewMode) this.populateAnswers();
      this.isLoading = false;
      this.isLoadingFeedback.emit(false)
    })
  }

  populateAnswers = () => {
    if(this.isOfflineInterview || this.calendarInvite){
      let fileName = this.savedReferenceDoc.split('/').pop();
      let blob: any = new Blob([""], { type: 'application/pdf' });
      blob["lastModifiedDate"] = "";
      blob["name"] = fileName;
      this.file = [blob];
      this.referenceDoc = this.savedReferenceDoc;
    };
    this.templateModels.forEach((eachModel: FormModel) => {
      eachModel.isValid = true;
      this.feedbackForm.forEach((eachFeedback: FormModel) => {
        if (eachModel.fieldName == eachFeedback.fieldName && eachModel.displaySection == eachFeedback.displaySection) {
          eachModel.answer = eachFeedback.answer;
          this.ansChange = eachModel.answer;
        }
      })
    })
  }

  getCandidateDetails = () => {
    this.eventServices.getUserByEmail(this.headerData?.candidateEmail).subscribe(response => {
      this.candidateDetails = response.data ? response.data : {};
      this.pdfSrc = this.candidateDetails?.resume;
      this.signedProfileUrl = this.candidateDetails?.profilePic;
    })
  }

  bindData(event: any, model: FormModel) {
    model.answer = [];
    switch (model.fieldType) {
      case 'checkbox': model.answer = event; break;
      case 'multiinput': {
        model.answer.push(model.fieldOptions?.map((option: FieldOptions) => option.value).join(' '));
      } break;
      case 'dropdown': {
        if (model.fieldName == 'Highest Degree - Year of Passing') {
          model.answer.push(Number(event))
        } else {
          model.answer.push(event)
        }
      } break;
      case 'input': {
        if (model.fieldName == 'Aadhar Card Number:') {
          model.answer.push(Number(event))
        } else {
          model.answer.push(event)
        }
      } break;
      case 'mobile': {
        model.answer.push(Number(event.split('_')[0]))
      } break;
      default: {
        model.answer.push(event)
      }
    }
  }

  submit() {
    this.clicked = true;
    let templateModels = this.templateCollections.flat(1).filter((eachModel: FormModel) => eachModel.visible);
    this.isFormValid = !templateModels.map((model: FormModel) => model.isValid).includes(false);

    if (this.isFormValid) {
      this.submitBtnLoading = true;
      let payload = { formDetails: templateModels };

      if (!this.inEditMode) {
        this.saveFeedback(payload);
      } else if (this.inEditMode) {
        this.updateFeedback(payload);
      }
    } else {
      let invalidFields = templateModels.filter((model: FormModel) => !model.isValid);
      this.invalidField = invalidFields[0];
    }
  }

  saveFeedback = (payload: any) => {
    let userType = this.globalService.privilegeRoles;
    if (this.userDetails && this.userDetails.email && this.userDetails.id && this.userDetails.name && userType) {
      payload.interrogatorEmail = this.userDetails.email;
      payload.interrogatorId = this.userDetails.id;
      payload.interrogatorName = this.userDetails.name;
      payload.interrogatorType = userType;
      if(this.isOfflineInterview || this.calendarInvite){
        payload.referenceDocument = this.referenceDoc;
      }
      this.formBuilderServices.saveFeedback(payload, this.headerData.eventId, this.headerData.stageId, this.headerData.userID).subscribe(res => {
        if (res.status == 200) {
          this.clicked = false;
          this.messageServices.add({ severity: 'success', summary: 'Success', detail: 'Feedback Submitted Successfully.' });
          if(this.calendarInvite){
            this.ms_authService.updateMeetingStatus(this.calendarInvite, 'completed').subscribe((res:any) => {
              this.submitBtnLoading = false;
              if(res.id){
                this.feedbackSubmittedModel = false;
                window.close();
              }
            });
          }
        } else if (res.status == 500) {
          this.messageServices.add({ severity: 'error', summary: 'Failed', detail: res.error });
        }
        else {
          this.messageServices.add({ severity: 'error', summary: 'Failed', detail: 'Something went wrong!' });
        }
        this.submitBtnLoading = !this.calendarInvite ? false : true;
      })
    }
    else {
      this.messageServices.add({ severity: 'error', summary: 'Failed', detail: 'Something went wrong!' });
    }
  }

  updateFeedback = (payload: any) => {
    if(this.isOfflineInterview || this.calendarInvite){
      payload.referenceDocument = this.referenceDoc;
    }
    this.formBuilderServices.updateFeedback(payload, this.headerData.eventId, this.headerData.stageId, this.headerData.userID).subscribe(res => {
      if (res.status == 200) {
        this.clicked = false;
        this.messageServices.add({ severity: 'success', summary: 'Success', detail: 'Feedback Updated Successfully.' });
      } else if (res.status == 500) {
        this.messageServices.add({ severity: 'error', summary: 'Failed', detail: res.error });
      }
      else {
        this.messageServices.add({ severity: 'error', summary: 'Failed', detail: 'Something went wrong!' });
      }
      this.submitBtnLoading = false;
    })
  }

  checkValid(event: any, model: FormModel) {
    model.isValid = event;
  }

  checkForDependancy = (model: FormModel): boolean => {
    if (model.dependant) {
      let templateModels = this.templateCollections.flat(1);
      let dependantField: any;

      templateModels.forEach((eachModel: FormModel) => {
        if (eachModel.fieldName === model.dependant?.dependantOn) dependantField = eachModel;
      })
      if (model.dependant.dependantValues.includes(dependantField.answer[0])) {
        model.visible = true;
        return true;
      }
      else {
        model.visible = false;
        model.answer = [];
        model.isValid = model.isMandatory ? false : true;
        return false;
      }
    } else return true;
  }

  downloadResume()
  {
    window.open(this.pdfSrc, '_blank')
  }

  downloadFeedback = () => {
    this.isLoading = true;
    this.eventServices.getFeedbackDownloadLink(this.headerData.eventId, this.headerData.userID, this.headerData.stageId).subscribe((res:any) => {
      this.isLoading = false;
      let downloadLink = res?.link;
      window.open(downloadLink);
    })
  }

  onSelectfile(event: any, acl?: boolean) {
    if (this.file.length) this.file.shift();
    this.file.push(...event.addedFiles);
    this.handleUpload(event, acl)
  }

  handleUpload(event: any, acl?: boolean) {
    const file = event.addedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileUpload(file, acl)
    };
  }

  fileUpload(file: any, acl?: boolean) {
    this.uploadingFile = true;
    let fileName = file.name.split(".");
    let fileExtension = fileName.pop();
    fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    const pay: any = {
      Bucket_name: this.assetBucketName,
      file_name: `feedback-referance/${nanoid(10)}/${new Date().getTime()}/${fileName}`,
      type: file.type,
    };
    acl ? pay['ACL'] = 'public-read' : null;
    const blobData: any = file;

    this.fileUploadService.getSignedUrl(pay).subscribe(
      (url: any) => {
        if (url) {
          const json = url;
          this.fileUploadService.uploadUsingSignedUrl(
            json.data,
            blobData
          ).subscribe(
            (r: any) => {
              if (r && r.status === 200) {
                this.referenceDoc = `${this.s3Domain}/${this.assetBucketName}/${pay.file_name}`;
                this.uploadingFile = false;
              }
            }
          );
        }
      }
    );
  }

  convertByte(sizeFormat: string, size: number) {
    let formattedSize = 0;

    if (sizeFormat === 'mb') {
      formattedSize = size * 1024 * 1024;
    }
    else if (sizeFormat === 'kb') {
      formattedSize = size * 1024;
    }

    return formattedSize;
  }

  onRemovefile() {
    this.file = [];
    this.referenceDoc = '';
  }

  updateInterviewStatus(status: string) { 
    return new Promise((resolve) => {
      let payload = {
        id: this.headerData.lobbyId,
        status: status,
        userId: this.headerData.userID,
        eventId: this.headerData.eventId,
        schoolId: this.headerData.schoolId,
        stageId: this.headerData.stageId
      };
      this.eventServices.updateInterviewStatus(payload).subscribe((response) => {
        if (!response) {
          this.messageService.add({ severity: 'error', summary: 'Unable to update', detail: "Updation fail, Please contact Adminstrator" });
          resolve(false);
        } else {
          resolve(true);
        }
      });
    })
  };

  endOfflineInterview = async () => {
    this.endBtnLoading = true;
    const UpdateInterview = await this.updateInterviewStatus('meetingEnd');
    let payload: any = {
      userId: this.headerData.userID,
      record: 'stop',
      eventId: this.headerData.eventId,
      stageId: parseInt(this.headerData.stageId)
    };
    this.eventServices.saveRecordingDuration(payload).subscribe(response => {
        console.log("Recording Stopped : ", response);
        this.endInterveiwModel = true;
        this.endBtnLoading = false;
    }) 
  }

  closeWindow = () => {
    this.endInterveiwModel = false;
    window.close();
  }

  ngOnDestroy(): void {
    this.inEditMode = false;
    this.inViewMode = false;
    this.onlyFeedbackView = false;
  }
}
