import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//Component Routes
import { EventsListComponent } from './events/events-list/events-list.component';
import { JobListComponent } from './job-list/job-list.component'
import { JitsiContaineComponent } from './job/jitsi-container/jitsi-container'
import { CandidateListComponent } from './job/candidate-list/candidate-list.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { ApplicationFormComponent } from './application-form/application-form.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
import { EmailTemplateComponent } from './email/email-template/email-template.component';
import { RoleListComponent } from './roles/role-list/role-list.component';
import { ManageSlotComponent } from './job/manage-slot/manage-slot.component';
import { GeneralSettingsComponent } from './settings/general-settings/general-settings.component';
import { RegistrationFormComponent } from './forms/registration-form/registration-form.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './route-guard/auth.guard';
import { LoginGuard } from './route-guard/login.guard';
import { TalentpoolManagementComponent } from './talentpool/talentpool-management/talentpool-management.component';
import { CandidatePanelComponent } from './candidate-panel/candidate-panel.component';
import { OtpVarificationComponent } from './job/otp-verification/otp-verification.component';
import { InterviewOfflineComponent } from './job/interview-offline/interview-offline.component';
import { LoginCallbackComponent } from './login-call-back/logincallback.component';

const routes: Routes = [
  // {
  //   path: '', component: EventsListComponent, canActivate: [AuthGuard], data: {
  //     title: 'Dashboard',
  //     breadcrumb: [
  //       { label: 'Dashboard', url: '' }
  //     ]
  //   },
  // },
  { path: '', redirectTo: 'events', pathMatch: 'full' },
  { path: 'login/callback', component: LoginCallbackComponent},
  { path: 'application-form/invitation', component: ApplicationFormComponent },
  {
    path: 'application-form/:jobId', component: ApplicationFormComponent, data: {
      title: 'Application Form',
      breadcrumb: [
        { label: 'Manage Drives', url: 'jobs' },
        { label: '{{jobName}}', url: 'jobs/candidates/:jobName' },
        { label: 'Application Form', url: '' },
      ]
    },
  },
  {
    path: 'events', component: EventsListComponent, canActivate: [AuthGuard], data: {
      title: 'Manage Events',
      breadcrumb: [
        { label: 'Manage Events', url: '' }
      ]
    },
  },
  {
    path: 'jitsiContainer', component: JitsiContaineComponent, canActivate: [AuthGuard]
  },
  {
    path: 'jobs', component: JobListComponent, canActivate: [AuthGuard], data: {
      title: 'Manage Drives',
      breadcrumb: [
        { label: 'Manage Drives', url: '' }
      ]
    },
  },
  {
    path: 'jobs/candidates/:jobName', component: CandidateListComponent, canActivate: [AuthGuard], data: {
      title: 'Drives Candidates',
      breadcrumb: [
        { label: 'Manage Drives', url: 'jobs' },
        { label: "{{jobName}}", url: '' }
      ]
    },
  },
  {
    path: 'events/candidates/:eventName', component: CandidateListComponent, canActivate: [AuthGuard], data: {
      title: 'Event Candidates',
      breadcrumb: [
        { label: 'Manage Events', url: 'events' },
        { label: "{{eventName}}", url: '' }
      ]
    },
  },
  {
    path: 'users', component: UserListComponent, canActivate: [AuthGuard], data: {
      title: 'Manage Users',
      breadcrumb: [
        { label: 'Manage Users', url: '' }
      ]
    },
  },
  {
    path: 'email-template', component: EmailTemplateComponent, canActivate: [AuthGuard], data: {
      title: 'Email Template',
      breadcrumb: [
        { label: 'Email Template', url: '' }
      ]
    },
  },
  {
    path: 'roles', component: RoleListComponent, canActivate: [AuthGuard], data: {
      title: 'Manage Job Roles',
      breadcrumb: [
        { label: 'Manage Job Roles', url: '' }
      ]
    },
  },
  {
    path: 'application-form/:jobName', component: ApplicationFormComponent, canActivate: [AuthGuard], data: {
      title: 'Manage Roles',
      breadcrumb: [
        { label: 'Manage Drives', url: 'jobs' },
        { label: '{{jobName}}', url: 'jobs/candidates/:jobName' },
        { label: 'Application Form', url: '' },
      ]
    },
  },
  {
    path: 'talentpool-management', component: TalentpoolManagementComponent, canActivate: [AuthGuard], data: {
      title: 'Manage Candidate',
      breadcrumb: [
        { label: 'Manage Candidate', url: '' },
      ]
    },
  },
  {
    path: ':jobName/:stageName/manage-slot', component: ManageSlotComponent, canActivate: [AuthGuard], data: {
      title: 'Manage Slots',
      breadcrumb: [
        { label: 'Manage Drives', url: 'jobs' },
        { label: '{{jobName}}', url: 'jobs/candidates/:jobName' },
        { label: 'Workflow', url: 'jobs/candidates/:jobName' },
        { label: '{{stageName}}', url: 'jobs/candidates/:jobName?view=workflow' },
        { label: 'Manage Slots', url: '' },
      ]
    },
  },
  { path: 'registration-form', component: RegistrationFormComponent },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  {
    path: 'candidatePanel', component: CandidatePanelComponent
  },
  {
    path: 'general-settings', component: GeneralSettingsComponent, canActivate: [AuthGuard], data: {
      title: 'General Settings',
      breadcrumb: [
        { label: 'General Settings', url: '' },
      ]
    },
  },
  {
    path: 'feedback-submission', component: InterviewOfflineComponent, canActivate: [AuthGuard], data: {
      title: 'Offline Interview',
      breadcrumb: [
        { label: 'Feedback Submission', url: '' },
      ]
    },
  },
  { path: 'verification', component: OtpVarificationComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
